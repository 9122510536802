import type * as org_srv from "@api/http_resv/org/org_srv";
import { type PayChannel } from "@api/http_resv/org/org_type";
import { isEmpty } from "lodash-es";
import { PAYCHANNEL_ICON } from "@/constants/icon";

import { Card } from "@/components/Card";
import SelectedIcon from "@/assets/icons/selected.svg?react";
import UnselectIcon from "@/assets/icons/unselect.svg?react";
import NoPayChannelIcon from "@/assets/icons/no_paychannel.svg?react";

interface PayChannelsProps {
  payChannels: org_srv.Org["payChannels"];
  value: PayChannel | Record<string, never>;
  onChange: (value: PayChannel) => void;
}

export const PayChannels: React.FC<PayChannelsProps> = ({
  payChannels,
  value,
  onChange,
}: PayChannelsProps) => {
  const isEmptyPayChannels = isEmpty(payChannels);

  if (isEmptyPayChannels) {
    return (
      <Card className="flex h-full flex-col items-center justify-center">
        <NoPayChannelIcon className="size-[100px]" />
        <span className="text-text-2 text-sm font-normal">
          暂无可用支付方式，请联系客服
        </span>
      </Card>
    );
  }

  return (
    <div>
      <Card className="mb-3">
        {payChannels.map((payChannel) => {
          return (
            <div className="flex justify-between py-4" key={payChannel.id}>
              <div className="flex items-center space-x-2">
                <img
                  className="size-[22px]"
                  src={PAYCHANNEL_ICON[payChannel.payKind]?.icon}
                  alt={payChannel.name}
                />
                <span className="text-text-2 text-base">
                  {PAYCHANNEL_ICON[payChannel.payKind].label}
                </span>
              </div>
              <div
                className="flex items-center space-x-2"
                onClick={() => onChange(payChannel)}
              >
                {value?.payKind === payChannel.payKind ? (
                  <SelectedIcon className="size-[22px]" />
                ) : (
                  <UnselectIcon className="size-[22px]" />
                )}
              </div>
            </div>
          );
        })}
      </Card>
    </div>
  );
};
