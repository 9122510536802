import { type FC } from "react";
import clsx from "clsx";
import StepperAdd from "@/assets/icons/stepper_add.svg?react";
import StepperMinus from "@/assets/icons/stepper_minus.svg?react";
import DisabledStepperMinus from "@/assets/icons/disabled_stepper_minus.svg?react";
import People from "@/assets/icons/people.svg?react";

export interface TenantCountProps {
  value: number;
  onChange?: (value: number) => void;
}

export const TenantCount: FC<TenantCountProps> = ({ value = 1, onChange }) => {
  const isRoomPath = window.location.pathname.includes("/room");

  return (
    <div
      className={clsx(
        "border-b-text-4/20 flex items-center justify-between  py-3",
        // 详情页面没有 border
        !isRoomPath && "border-b",
      )}
    >
      <div className="flex items-center space-x-3">
        <People className="size-6" />
        <div className="text-text-666 text-sm">房客人数</div>
      </div>

      <div className="flex items-center">
        {value === 1 ? (
          <DisabledStepperMinus className="size-6" />
        ) : (
          <StepperMinus
            className="size-6"
            onClick={() => onChange?.(value - 1)}
          />
        )}
        <div className="flex min-w-12 justify-center">{value}</div>
        <StepperAdd className="size-6" onClick={() => onChange?.(value + 1)} />
      </div>
    </div>
  );
};
