/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { webapi } from "@/utils/webapi";
import { type User } from "./user_type";
import { type Tenant } from "../common/common_type";

export namespace UserService {
  // 获取用户信息
  export function Info(req: InfoReq, config?: any) {
    return webapi.post<InfoResp>("/api/v1/UserService_Info", req, config);
  }
}
// Info
export interface InfoReq {}

export interface InfoResp {
  user: User;
  defaultTenant?: Tenant;
}
