import React from "react";
import { cn } from "@/lib/utils";

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  titleClassName?: string;
  titleColor?: string;
}

export const Card = ({
  title,
  className,
  children,
  titleClassName,
  titleColor,
  ...props
}: CardProps) => {
  const hasTitle = Boolean(title);
  return (
    <div className={cn("rounded-xl bg-white p-3", className)} {...props}>
      {hasTitle && (
        <div
          className={cn("text-base font-medium text-text-1", titleClassName)}
          style={titleColor ? { color: titleColor } : {}}
        >
          {title}
        </div>
      )}
      {children}
    </div>
  );
};
