import { useMemo, useState } from "react";
import { Image, List, NavBar } from "react-vant";
import { Helmet } from "react-helmet";
import { generatePath, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { isNil, isEmpty } from "lodash-es";
import NiceModal from "@ebay/nice-modal-react";
import type * as room_type from "@api/http_resv/room/room_type";
import type * as room_srv from "@api/http_resv/room/room_srv";
import { RoomService } from "@api/http_resv/room/room_srv";
import { useSetState } from "ahooks";
import SearchIcon from "@/assets/icons/search.svg?react";
import CancelIcon from "@/assets/icons/cancel.svg?react";
import EmptySearch from "@/assets/images/empty_search.svg?react";
import LocationIcon from "@/assets/icons/location.svg?react";
import SubwayIcon from "@/assets/icons/subway.svg?react";
import { genPageTitle } from "@/utils";
import { getRoomInfo, getDistanceInfo } from "@/utils/roomInfo";
import { stringify, toMMDD } from "@/lib/utils";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "@/constants/pagination";
import {
  DateRangePopup,
  type DateRangePopupProps,
} from "@/components/DateRangePopup";
import NoPhoto from "@/assets/icons/no_photo.svg?react";

import { PATHS } from "@/constants/path";
import { getMoneyText } from "@/utils/money";
import { useUrlSearchState } from "@/hooks/useUrlSearchState";
import { genSmallRoomCoverUrl } from "@/utils/file";
import { SearchPopup } from "@/pages/Home/SearchPopup";
import Distance from "@/assets/icons/distance.svg?react";

export const RoomSearchItemSkeleton = () => {
  return (
    <div className="flex flex-col">
      <div className="relative mb-2 h-[200px] overflow-hidden rounded-2xl bg-[#F2F3F5]">
        <div className="h-[200px] w-full" />
        <div className="absolute bottom-2 right-2 flex h-[44px] w-[140px] items-center space-x-2 rounded-xl bg-[#EBECF0] px-2 py-1" />
      </div>
      <div className="mb-2 h-[30px] rounded-xl bg-[#F2F3F5]" />
      <div className="mb-2 h-6 w-3/5 rounded-xl bg-[#F2F3F5]" />
      <div className="mb-2 h-6 rounded-xl bg-[#F2F3F5]" />
      <div className="mb-2 h-16 w-3/5 rounded-xl bg-[#F2F3F5]" />
    </div>
  );
};

interface RoomSearchState {
  startDate?: string;
  endDate?: string;
  adultCount?: number;
  address?: string;
  lat?: number;
  lng?: number;
}
export const RoomList = () => {
  const navigate = useNavigate();
  const { priceQuery, queryObj, point } = useUrlSearchState();
  const [state, setState] = useSetState<RoomSearchState>(() => ({
    startDate: priceQuery?.startDate || undefined,
    endDate: priceQuery?.endDate || undefined,
    adultCount: priceQuery?.adultCount || 1,
    lat: point?.lat || undefined,
    lng: point?.lng || undefined,
    address: queryObj.address || undefined,
  }));

  const [pagination, setPagination] = useSetState<{
    page: number;
    pageSize: number;
  }>({
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [finished, setFinished] = useState(false);
  const [rooms, setRooms] = useState<room_type.RoomInList[]>([]);

  const ignoreThisYear = useMemo(() => {
    if (isNil(priceQuery)) {
      return false;
    }
    const isThisYear = dayjs(priceQuery?.endDate as string).isSame(
      dayjs(),
      "years",
    );
    if (isThisYear) {
      const isSameYear = dayjs(priceQuery.startDate as string).isSame(
        dayjs(priceQuery.endDate as string),
        "years",
      );
      return isSameYear && isThisYear;
    }

    return false;
  }, [priceQuery]);

  const noMoreTipVisible = finished && rooms.length > 0;
  const noDataVisible = finished && rooms.length === 0;

  const resetRequestState = () => {
    setPagination({ page: DEFAULT_PAGE, pageSize: DEFAULT_PAGE_SIZE });
    setFinished(false);
    setRooms([]);
  };

  const onLoad = async () => {
    const params: room_srv.ListRoomReq = {
      current: pagination.page,
      pageSize: pagination.pageSize,
      priceQuery: {
        startDate: state.startDate!,
        endDate: state.endDate!,
        adultCount: state.adultCount!,
      },
    };

    if (state.lat && state.lng) {
      params.point = {
        lat: state.lat,
        lng: state.lng,
      };
    }
    const resp = await RoomService.ListRoom(params);

    const data = [...rooms];
    data.push(...(resp.rooms || []));
    setRooms(data);
    if (data.length >= resp.total) {
      setFinished(true);
    } else {
      setPagination({
        page: pagination.page + 1,
      });
    }
  };

  const showDateRangePopup = () => {
    const params: DateRangePopupProps = {
      value: {
        startDate: state.startDate!,
        endDate: state.endDate!,
      },
    };
    NiceModal.show(DateRangePopup, params)
      .then((v: any) => {
        if (!v) return;

        resetRequestState();
        const copied: Partial<RoomSearchState> = {
          ...state,
          ...v,
        };

        setState((prev) => ({
          ...prev,
          ...v,
        }));

        navigate(`${PATHS.ROOM_SEARCH}?${stringify(copied)}`, {
          replace: true,
        });
      })
      .catch(() => {});
  };

  const showSearchPopup = () => {
    NiceModal.show(SearchPopup, {})
      .then((v: any) => {
        if (!v) return;
        resetRequestState();

        const copied: Partial<RoomSearchState> = {
          ...state,
          lat: v.point.lat,
          lng: v.point.lng,
          address: v.address,
        };
        setState((prev) => ({
          ...prev,
          lat: v.point.lat,
          lng: v.point.lng,
          address: v.address,
        }));
        navigate(`${PATHS.ROOM_SEARCH}?${stringify(copied)}`, {
          replace: true,
        });
      })
      .catch(() => {});
  };

  const deleteAddress = (e: React.MouseEvent) => {
    e.stopPropagation();
    resetRequestState();

    setState((prev) => ({
      ...prev,
      address: "",
      lat: undefined,
      lng: undefined,
    }));

    const copied: Partial<RoomSearchState> = {
      ...queryObj,
      address: "",
      lat: undefined,
      lng: undefined,
    };
    navigate(`${PATHS.ROOM_SEARCH}?${stringify(copied)}`, { replace: true });
  };

  return (
    <div>
      <Helmet>
        <title>{genPageTitle("房间列表")}</title>
      </Helmet>

      <NavBar
        title="房间列表"
        leftText="返回"
        fixed={true}
        placeholder={true}
        zIndex={50}
        onClickLeft={() => {
          navigate(`${PATHS.HOME}?${stringify(state)}`, {
            replace: true,
          });
        }}
      />
      {/* 搜索条件 */}
      <div className="bg-page-bg sticky inset-x-0 top-10 z-10 flex space-x-2 px-4 py-3">
        <div
          className="flex flex-col justify-center rounded-xl bg-white px-3"
          onClick={showDateRangePopup}
        >
          <div className="flex items-center text-xs font-medium">
            <span className="text-text-666 mr-2">住</span>
            <span className="text-easbnb-brand">
              {state?.startDate
                ? toMMDD(state?.startDate, { ignoreThisYear })
                : "请选择"}
            </span>
          </div>
          <div className="flex items-center text-xs font-medium">
            <span className="text-text-666 mr-2">离</span>
            <span className="text-easbnb-brand">
              {state?.endDate
                ? toMMDD(state?.endDate, { ignoreThisYear })
                : "请选择"}
            </span>
          </div>
        </div>

        <div
          className="flex flex-1 items-center space-x-2 rounded-xl bg-white p-3"
          onClick={showSearchPopup}
        >
          <SearchIcon className="size-4" />
          {queryObj?.address ? (
            <div className="text-text-1 line-clamp-1 w-full flex-1 text-ellipsis text-sm">
              {queryObj?.address}
            </div>
          ) : (
            <div className="text-text-4 w-full flex-1 text-sm">
              搜索附近的地区或车站
            </div>
          )}

          {queryObj?.address && (
            <CancelIcon className="size-4" onClick={(e) => deleteAddress(e)} />
          )}
        </div>
      </div>

      {/* 列表 */}
      <div className="bg-page-bg flex w-full flex-1 flex-col space-y-6 px-4">
        <List finished={finished} onLoad={onLoad} loadingText="加载中...">
          {rooms.map((roomInList) => (
            <RoomSearchItem
              roomInList={roomInList}
              state={queryObj}
              key={roomInList.room.id}
            />
          ))}
        </List>

        {noDataVisible && (
          <div className="flex flex-col items-center pt-24">
            <EmptySearch />
            <div className="text-text-4 mt-3 text-sm">暂无搜索结果</div>
          </div>
        )}

        {noMoreTipVisible && (
          <div className="text-text-3 flex justify-center py-6 text-sm">
            已经到底啦～
          </div>
        )}
      </div>
    </div>
  );
};

const RoomSearchItem = ({
  roomInList,
  state,
}: {
  roomInList: room_type.RoomInList;
  state: Partial<RoomSearchState>;
}) => {
  const navigate = useNavigate();
  const roomInfoText = getRoomInfo({
    area: roomInList.room.roomSize,
    bed: roomInList.room.singleBedCount,
    doubleBed: roomInList.room.doubleBedCount,
  });
  const distanceText = getDistanceInfo(roomInList?.distance ?? "");

  const goToRoomDetail = (id: string) => {
    const query = stringify(state);

    const path = generatePath(`${PATHS.ROOM}/:id`, { id });
    navigate(`${path}?${query}`, { replace: true });
  };

  return (
    <div className="mb-3 flex flex-col" key={roomInList.room.id}>
      <div
        className="relative mb-2 h-[200px] overflow-hidden rounded-xl bg-[#F2F0F4]"
        onClick={() => goToRoomDetail(roomInList.room.id)}
      >
        {roomInList?.imageUri ? (
          <Image src={genSmallRoomCoverUrl(roomInList.imageUri)} fit="cover" />
        ) : (
          <div className="flex h-[200px] w-full items-center justify-center bg-[#F2F0F4]">
            <NoPhoto />
            <span className="text-text-3 absolute bottom-1/4 translate-y-1/2 text-sm">
              暂无图片
            </span>
          </div>
        )}
        {roomInList?.quotedPrice && roomInList?.basePrice && (
          <div className="absolute bottom-2 right-2 flex items-center space-x-2 rounded-xl bg-white/85 px-2 py-1">
            <span className="font-DINPro text-lg font-medium text-[#F74D36]">
              {getMoneyText(
                roomInList?.quotedPrice?.amount,
                roomInList?.quotedPrice?.currency,
                { hidePlusSign: true },
              )}
            </span>
            {roomInList?.quotedPrice?.amount !==
              roomInList?.basePrice?.amount && (
              <span className="font-DINPro text-text-2 text-xs font-medium line-through">
                {getMoneyText(
                  roomInList?.basePrice?.amount,
                  roomInList?.basePrice?.currency,
                  { hideLabel: true, hidePlusSign: true },
                )}
              </span>
            )}
          </div>
        )}
        {roomInList?.lowestPrice && (
          <div className="absolute bottom-2 right-2 flex items-center space-x-2 rounded-xl bg-white/85 px-2 py-1">
            <span className="font-DINPro text-lg font-medium text-[#F74D36]">
              {getMoneyText(
                roomInList?.lowestPrice?.amount,
                roomInList?.lowestPrice?.currency,
                { hidePlusSign: true },
              )}
            </span>
            <span className="font-DINPro text-text-2 text-xs font-medium ">
              起/晚
            </span>
          </div>
        )}
      </div>
      <div className="text-text-1 mb-2 text-xl font-medium">
        {roomInList.room.title}
      </div>
      <div className="mb-2 flex items-center space-x-2">
        {!isEmpty(distanceText) && (
          <span className="flex items-center rounded-md border border-[#EC642B] bg-[#FDF6EF] px-1.5 py-1 text-sm ">
            <Distance className="size-2" />
            <span className="ml-1 text-[#EC642B]">{distanceText}</span>
          </span>
        )}
        {roomInList.room.roomSize > 0 && (
          <span className="text-text-666 text-sm">{roomInfoText}</span>
        )}
        {roomInList.room.personCapacity > 0 && (
          <span className="text-easbnb-brand text-sm">
            可住{roomInList.room.personCapacity}人
          </span>
        )}
      </div>
      {Boolean(roomInList.room.address) && (
        <div className="mb-2 flex items-start">
          <div>
            <LocationIcon className="mr-1 mt-0.5 size-4" />
          </div>
          <div className="text-text-666 text-sm">{roomInList.room.address}</div>
        </div>
      )}
      {Boolean(roomInList.room.traffic) && (
        <div className="mb-2 flex items-start">
          <div>
            <SubwayIcon className="mr-1 mt-0.5 size-4" />
          </div>
          <div className="text-text-666 line-clamp-3 flex-1 whitespace-pre-line text-sm">
            {roomInList.room.traffic}
          </div>
        </div>
      )}
    </div>
  );
};
