import { isEmpty, groupBy } from "lodash-es";
import { useMemo } from "react";
import type * as common_type from "@api/http_pms/common/common_type";

import {
  ROOM_PARTITION_AREA_MAP,
  type FilteredPartitionKeys,
} from "@/constants/roomPartition";

interface Props {
  uri: string;
  rank: number;
  partition: common_type.PartitionKind;
  index?: number;
}
export const useSwiperData = (data: Props[]) => {
  const swiperData = useMemo(() => {
    if (isEmpty(data)) {
      return [];
    }

    const groupByData = groupBy(
      data.map((item, index) => ({ ...item, index })),
      (item) => item.partition,
    );

    const result = Object.keys(groupByData).map((key) => {
      const roomPartition =
        ROOM_PARTITION_AREA_MAP[key as unknown as FilteredPartitionKeys];
      return {
        ...roomPartition,
        images: groupByData[key],
      };
    });

    return result;
  }, [data]);

  return swiperData;
};
