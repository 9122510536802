export const PATHS = {
  HOME: "/",
  ORDER: "/order",
  ORDER_CONFIRM: "/order_confirm",
  ORDER_AGREEMENT: "/order_agreement",
  ME: "/me",
  LOGIN: "/login",
  PAY: "/pay",
  PAY_RESULT: "/pay_result",
  ROOM: "/room",
  ROOM_SEARCH: "/room_search",
  ROOM_PHOTO: "/room_photo",
};
