import * as common_type from "@api/http_pms/common/common_type";

// 直接列出需要的类型
export type FilteredPartitionKeys =
  | common_type.PartitionKind.Bedroom
  | common_type.PartitionKind.Parlor
  | common_type.PartitionKind.Kitchen
  | common_type.PartitionKind.Bathroom
  | common_type.PartitionKind.Exterior
  | common_type.PartitionKind.Other;

export type RoomPartitionMap<
  K extends number,
  T extends Record<string, any> = {},
> = Record<K, RoomPartition<T>>;

export type RoomPartition<T extends Record<string, any> = {}> = {
  label: string;
  name: string;
} & T;

// 使用手动列出的联合类型
export const ROOM_PARTITION_AREA_MAP: RoomPartitionMap<FilteredPartitionKeys> =
  {
    [common_type.PartitionKind.Bedroom]: {
      label: "bedroom",
      name: "卧室",
    },
    [common_type.PartitionKind.Parlor]: {
      label: "parlor",
      name: "客厅",
    },
    [common_type.PartitionKind.Kitchen]: {
      label: "kitchen",
      name: "厨房",
    },
    [common_type.PartitionKind.Bathroom]: {
      label: "bathroom",
      name: "卫浴",
    },
    [common_type.PartitionKind.Exterior]: {
      label: "exterior",
      name: "外观",
    },
    [common_type.PartitionKind.Other]: {
      label: "other",
      name: "其他",
    },
  };
