import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NiceModal from "@ebay/nice-modal-react";
import { useRequest } from "ahooks";
import CloseStoreIcon from "@/assets/icons/close_store.svg?react";
import { Loading } from "@/components/Loading";
import { BottomNavigationBarRouterLayout } from "@/components/layouts";
import { Home } from "./Home";
import { OrderDetail, OrderList, OrderAgreement } from "./Order";
import { Me } from "./Me";
import { PATHS } from "@/constants/path";
import { Login } from "./Login";
import { Pay } from "./Pay";
import { OrderConfirm } from "./Order/Confirm";
import { RoomList, RoomDetail, RoomPhoto } from "./Room";
import { AuthGuard } from "@/components/AuthGuard";
import { useOrgInfoStore } from "@/stores/useOrgInfoStore";
import { BackButtonListener } from "@/components/BackButtonListener";
import { PayResult } from "./Pay/Result";
import { useUserInfoStore } from "@/stores/useUserInfoStore";

const router = createBrowserRouter([
  {
    element: (
      <NiceModal.Provider>
        <BackButtonListener />
      </NiceModal.Provider>
    ),
    children: [
      {
        path: PATHS.LOGIN,
        element: <Login />,
      },
      {
        path: `${PATHS.ORDER_CONFIRM}/:id`,
        element: <OrderConfirm />,
      },
      {
        path: `${PATHS.ORDER}/:id`,
        element: (
          <AuthGuard>
            <OrderDetail />
          </AuthGuard>
        ),
      },
      {
        path: `${PATHS.ORDER_AGREEMENT}/:id`,
        element: (
          <AuthGuard>
            <OrderAgreement />
          </AuthGuard>
        ),
      },
      {
        path: `${PATHS.PAY}/:id`,
        element: <Pay />,
      },
      {
        path: `${PATHS.PAY_RESULT}/:id`,
        element: <PayResult />,
      },
      {
        path: `${PATHS.ROOM}/:id`,
        element: <RoomDetail />,
      },
      {
        path: PATHS.ROOM_SEARCH,
        element: <RoomList />,
      },
      {
        path: `${PATHS.ROOM_PHOTO}/:id`,
        element: <RoomPhoto />,
      },
      {
        path: PATHS.HOME,
        element: <BottomNavigationBarRouterLayout />,
        children: [
          {
            path: PATHS.HOME,
            element: <Home />,
          },
          {
            path: PATHS.ORDER,
            element: (
              <AuthGuard>
                <OrderList />
              </AuthGuard>
            ),
          },
          {
            path: PATHS.ME,
            element: <Me />,
          },
        ],
      },
    ],
  },
]);

function App() {
  const { loading, data, error } = useRequest(async () => {
    void useUserInfoStore.getState().fetchUserInfo();
    return await useOrgInfoStore.getState().fetchOrgInfo();
  });

  if (loading) {
    return (
      <div className="flex size-full items-center justify-center">
        <Loading />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex h-4/5 flex-col items-center justify-center bg-[#FAFAFA]">
        <CloseStoreIcon />
        <span className="text-text-3 text-sm font-normal">该店铺异常</span>
      </div>
    );
  }

  if (!data?.org) {
    return (
      <div className="flex h-4/5 flex-col items-center justify-center bg-[#FAFAFA]">
        <CloseStoreIcon />
        <span className="text-text-3 text-sm font-normal">该店铺不存在</span>
      </div>
    );
  }

  return <RouterProvider router={router} />;
}

export default App;
