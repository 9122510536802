import { Link, useLocation } from "react-router-dom";
import { isNil } from "lodash-es";
import { BOTTOM_NAVIGATION_BAR_ITEMS } from "@/constants/tabBar";
import { cn } from "@/lib/utils";
import { FixedBottomWrapper } from "../FixedBottomWrapper";
import { useUserInfoStore } from "@/stores/useUserInfoStore";

export const BottomNavigationBar = () => {
  const location = useLocation();
  const { pathname } = location;
  const userInfo = useUserInfoStore((s) => s.userInfo);
  const bottomItems = BOTTOM_NAVIGATION_BAR_ITEMS.filter((el) =>
    !isNil(userInfo) ? true : !el.needLogin,
  );

  return (
    <FixedBottomWrapper className="z-50 mx-auto flex min-h-[70px] max-w-[750px] justify-center rounded-t-[35px] bg-white">
      {bottomItems.map((el) => (
        <Link
          to={el.path}
          key={el.path}
          className="flex flex-1 flex-col items-center pt-3"
        >
          {pathname === el.path ? el.selectedIcon : el.icon}
          <div
            className={cn(
              "mt-1.5 text-sm",
              pathname === el.path ? "text-text-1" : "text-text-2",
            )}
          >
            {el.label}
          </div>
        </Link>
      ))}
    </FixedBottomWrapper>
  );
};

export const BottomNavigationBarPlaceholder = () => {
  return <div className="h-24 w-full" />;
};
