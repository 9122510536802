import { Helmet } from "react-helmet";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useCountDown, useRequest } from "ahooks";
import { OrderService } from "@api/http_resv/order/order_srv";
import { isNil, isUndefined, isEmpty } from "lodash-es";
import dayjs from "dayjs";
import { NavBar } from "react-vant";
import { useState } from "react";
import { Loading } from "@/components/Loading";

import { Card } from "@/components/Card";
import { PLACEHOLDER_TEXT } from "@/constants/text";
import {
  copyToClipboard,
  parseToNumber,
  stringify,
  toYYYYMMDDHHmmss,
} from "@/lib/utils";
import { PageLayout } from "@/components/layouts";
import {
  RoomCard,
  CheckInOutCard,
  OrderStatusCard,
  AgreementCard,
} from "../components";
import { FixedBottomWrapper } from "@/components/FixedBottomWrapper";
import { genPageTitle } from "@/utils";
import { makeStorageUrl } from "@/utils/file";
import { getOrderStatus, OrderStatusEnum } from "@/utils/orderStatus";
import { PATHS } from "@/constants/path";

export const OrderDetail = () => {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const [orderStatus, setOrderStatus] = useState<OrderStatusEnum>();

  const { data, loading, run } = useRequest(
    async () => {
      const resp = await OrderService.Get({
        id: id!,
      });

      const countDownDate = resp.order.order.isCancelled
        ? undefined
        : Math.max(
            Number(resp?.order.order.cancelledAt) * 1000,
            Date.now() + 5000,
          );

      return { ...resp.order, countDownDate };
    },
    {
      ready: !isUndefined(id),
      onSuccess: (resp) => {
        const s = getOrderStatus({
          dateStart: resp.order.startDate,
          dateEnd: resp.order.endDate,
          paidAt: resp.order.paidAt,
          isCancelled: resp.order.isCancelled,
        });

        setOrderStatus(s);
      },
    },
  );

  const [, formattedRes] = useCountDown({
    targetDate: data?.countDownDate,
    onEnd: () => {
      run();
    },
  });

  const onGoToPay = () => {
    if (isNil(data)) {
      return;
    }

    const query =
      data.order.needSignContract &&
      (isEmpty(data?.order.signedAt) || isUndefined(data?.order.signedAt))
        ? stringify({
            orderId: data.order.id,
            signedOrderId: data.order.signedOrderId,
            signAt: "",
          })
        : stringify({
            email: data?.tenant.email,
            signedOrderId: data.order.signedOrderId,
          });

    const path =
      data?.order.needSignContract && isEmpty(data?.order.signedAt)
        ? generatePath(`${PATHS.ORDER_AGREEMENT}/:id`, { id: data?.order.id })
        : generatePath(`${PATHS.PAY}/:id`, { id: data?.order.id });
    navigate(`${path}?${query}`, { replace: true });
  };

  if (!id) {
    return (
      <div className="flex h-[50vh] w-full items-center justify-center">
        <Helmet>
          <title>{genPageTitle("订单详情")}</title>
        </Helmet>
        <div className="text-text-4 text-sm">无法获取订单信息</div>
      </div>
    );
  }

  return (
    <PageLayout className="flex h-screen flex-col overflow-y-auto px-4 !pt-0 pb-24">
      <Helmet>
        <title>{genPageTitle("订单详情")}</title>
      </Helmet>

      {loading && (
        <div className="flex h-1/2 w-full items-center justify-center">
          <Loading />
        </div>
      )}

      {!isUndefined(data) && !loading && (
        <div className="flex flex-col">
          <NavBar
            title="订单详情"
            leftText="返回"
            fixed={true}
            placeholder={true}
            zIndex={50}
            onClickLeft={() => {
              navigate(PATHS.ORDER, { replace: true });
            }}
          />
          <div className="flex flex-col space-y-3 pt-3">
            <OrderStatusCard
              orderInDetail={data}
              orderStatus={orderStatus}
              formattedRes={formattedRes}
            />
            <RoomCard
              room={data.room}
              imageUri={makeStorageUrl(data.roomImageUri)}
              quotedPrice={data.order.price}
            />
            <CheckInOutCard
              startDate={data.order.startDate}
              endDate={data.order.endDate}
              adultCount={data.order.adultCount}
            />
            {Boolean(data?.order.needSignContract) && (
              <AgreementCard
                signedOrderId={data?.order.signedOrderId}
                orderId={data?.order.id}
                signedAt={data?.order.signedAt}
                contractName={data?.order.contractName}
              />
            )}
            {Boolean(!data?.hideCheckInGuideCard) && (
              <Card title="入住方式">
                {data?.hideCheckInGuideText ? (
                  <div className="mt-3 rounded-lg bg-[#F5F7FB] py-2 text-center">
                    <span className="text-text-666 whitespace-pre-line  text-sm">
                      入住当日至退房前日可查看
                    </span>
                  </div>
                ) : (
                  <div className="text-text-666 whitespace-pre-line pt-3 text-sm">
                    {data?.checkInGuide}
                  </div>
                )}
              </Card>
            )}
            {Boolean(!data?.hidePaidMessageCard) && (
              <Card title="房客须知">
                {data?.hidePaidMessageText ? (
                  <div className="mt-3 rounded-lg bg-[#F5F7FB] py-2 text-center">
                    <span className="text-text-666 whitespace-pre-line  text-sm">
                      付款后可查看房客须知
                    </span>
                  </div>
                ) : (
                  <div className="text-text-666 whitespace-pre-line pt-3 text-sm">
                    {data?.paidMessage}
                  </div>
                )}
              </Card>
            )}
            {Boolean(!data?.hidePolicyCard) && (
              <Card title="退订政策">
                <div className="text-text-666 whitespace-pre-line pt-3 text-sm">
                  {data?.policy}
                </div>
              </Card>
            )}
            {!isUndefined(data?.tenant) && (
              <Card title="联系人信息">
                <div className="flex flex-col space-y-4 pt-3">
                  <div className="flex items-start">
                    <div className="text-text-666 w-[100px] text-left text-sm">
                      姓名
                    </div>
                    <div className="text-text-1 line-clamp-1 flex-1 text-ellipsis text-sm">
                      {data.tenant.name || PLACEHOLDER_TEXT}
                    </div>
                  </div>
                  <div className="flex items-start">
                    <div className="text-text-666 w-[100px] text-left text-sm">
                      联系方式
                    </div>
                    <div className="text-text-1 line-clamp-1 flex-1 text-ellipsis text-sm">
                      {data.tenant.countryCode
                        ? `+${data.tenant.countryCode} `
                        : " "}
                      {data.tenant.phoneNumber || PLACEHOLDER_TEXT}
                    </div>
                  </div>
                  <div className="flex items-start">
                    <div className="text-text-666 w-[100px] text-left text-sm">
                      邮箱地址
                    </div>
                    <div className="text-text-1 line-clamp-1 flex-1 text-ellipsis text-sm">
                      {data.tenant.email || PLACEHOLDER_TEXT}
                    </div>
                  </div>
                  <div className="flex items-start">
                    <div className="text-text-666 w-[100px] text-sm">
                      备注信息
                    </div>
                    <div className="text-text-1 flex-1 text-sm">
                      {data.remark || PLACEHOLDER_TEXT}
                    </div>
                  </div>
                </div>
              </Card>
            )}
            <Card title="订单信息">
              <div className="flex flex-col space-y-4 pt-3">
                <div className="flex items-center">
                  <div className="text-text-666 text-sm">订单编号</div>
                  <div className="text-text-1 line-clamp-1 flex-1 text-ellipsis text-right text-sm">
                    {data.order.id}
                  </div>
                  <div
                    className="text-easbnb-brand ml-2 text-sm"
                    onClick={() => {
                      copyToClipboard(data.order.id);
                    }}
                  >
                    复制
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="text-text-666 pr-6 text-sm">创建时间</div>
                  <div className="text-text-1 line-clamp-1 flex-1 text-ellipsis text-right text-sm">
                    {parseToNumber(data.order.createdAt)
                      ? toYYYYMMDDHHmmss(
                          dayjs.unix(parseToNumber(data.order.createdAt)!),
                        )
                      : PLACEHOLDER_TEXT}
                  </div>
                </div>

                {/* 只有支付成功才展示 */}
                {!isUndefined(data.order.paidAt) && (
                  <div className="flex items-center">
                    <div className="text-text-666 pr-6 text-sm">支付时间</div>
                    <div className="text-text-1 line-clamp-1 flex-1 text-ellipsis text-right text-sm">
                      {parseToNumber(data.order.paidAt)
                        ? toYYYYMMDDHHmmss(
                            dayjs.unix(parseToNumber(data.order.createdAt)!),
                          )
                        : PLACEHOLDER_TEXT}
                    </div>
                  </div>
                )}
              </div>
            </Card>
          </div>
        </div>
      )}

      {orderStatus === OrderStatusEnum.Unpaid && (
        <FixedBottomWrapper className="flex w-full items-center justify-center bg-white py-3">
          <button
            type="button"
            className="bg-resv-black rounded-full px-20 py-3 text-base font-medium text-white"
            onClick={onGoToPay}
          >
            {data?.order.needSignContract &&
            (isEmpty(data?.order.signedAt) || isUndefined(data?.order.signedAt))
              ? "签署协议并支付"
              : "去支付"}
          </button>
        </FixedBottomWrapper>
      )}
    </PageLayout>
  );
};
