import { Helmet } from "react-helmet";
import NiceModal from "@ebay/nice-modal-react";
import { useSetState } from "ahooks";
import { useNavigate } from "react-router-dom";
import { isNil } from "lodash-es";
import * as const_enum from "@api/http_pms/const/const_enum";
import type * as OrgConfig from "@api/http_resv/org/org_type";

import { useState } from "react";
import defaultBgImg from "@/assets/images/default_bg.png";
import defaultWellcomeTextImg from "@/assets/images/default_wellcometext.png";
import { stringify } from "@/lib/utils";
import IconSearch from "@/assets/icons/search.svg?react";
import { SearchPopup } from "./SearchPopup";
import { PATHS } from "@/constants/path";
import { genPageTitle } from "@/utils";
import { makeImageUrl, makeStorageUrl } from "@/utils/file";

import { TenantCount } from "@/components/TenantCount";
import { type DateRange, LodgingDates } from "@/components/LodgingDates";
import { AREA_OPTION_MAP } from "@/constants/options";
import { useOrgInfoStore } from "@/stores/useOrgInfoStore";
import { useUrlSearchState } from "@/hooks/useUrlSearchState";

export interface RoomSearchState {
  startDate?: string;
  endDate?: string;
  adultCount?: number;
  address?: string;
  lat?: number;
  lng?: number;
}

export const Home = () => {
  const navigate = useNavigate();
  const { queryObj } = useUrlSearchState();

  const [state, setState] = useSetState<
    Omit<RoomSearchState, "startDate" | "endDate" | "adultCount">
  >({
    address: queryObj.address || "",
    lat: queryObj.lat || undefined,
    lng: queryObj.lng || undefined,
  });

  const [dateState, setDateState] = useState<DateRange>(() => {
    return {
      startDate: queryObj?.startDate ?? "",
      endDate: queryObj?.endDate ?? "",
    };
  });

  const [countState, setCountState] = useState(queryObj.adultCount || 1);

  const onChooseHotPlace = (item: OrgConfig.PopularLocation) => {
    navigate(
      `${PATHS.ROOM_SEARCH}?${stringify({ ...dateState, adultCount: countState, address: item.name, lat: item.point.lat, lng: item.point.lng })}`,
      { replace: true },
    );
  };

  const orgInfo = useOrgInfoStore((s) => s.data);

  const areaLabel = !isNil(orgInfo?.org?.area)
    ? AREA_OPTION_MAP[orgInfo?.org.area].label
    : AREA_OPTION_MAP[const_enum.Area.UnknownArea].label;

  const showSearchPopup = () => {
    NiceModal.show(SearchPopup, {})
      .then((v: any) => {
        if (!v) return;

        setState((prev) => ({
          ...prev,
          lng: v.point.lng,
          lat: v.point.lat,
          address: v.address,
        }));
      })
      .catch(() => {});
  };

  const onSearch = () => {
    navigate(
      `${PATHS.ROOM_SEARCH}?${stringify({ ...state, ...dateState, adultCount: countState })}`,
      { replace: true },
    );
  };

  const handleCountChange = (value: number) => {
    if (value <= 0) return;
    setCountState(value);
  };

  return (
    <div>
      <Helmet>
        <title>{genPageTitle("首页")}</title>
      </Helmet>

      <div
        className="flex size-full h-[35vh] items-center justify-center bg-cover bg-center bg-no-repeat object-cover"
        style={{
          backgroundImage: `${
            orgInfo?.org?.mainImage
              ? `url(${makeImageUrl(orgInfo.org.mainImage, 430, 320, "s")})`
              : `url(${defaultBgImg})`
          }`,
        }}
      >
        {orgInfo?.org?.welcomeKind === 0 && (
          <div
            className="mb-[7.5vw] flex size-full h-[6vh] w-4/5 items-center justify-center bg-cover bg-center bg-no-repeat object-cover"
            style={{
              backgroundImage: `url(${defaultWellcomeTextImg})`,
            }}
          />
        )}
        {orgInfo?.org?.welcomeKind !== 0 && (
          <>
            {orgInfo?.org?.welcomeText && orgInfo?.org?.welcomeKind === 1 && (
              <span className="mb-[7.5vw] w-4/5 truncate text-center text-3xl text-white">
                {orgInfo.org.welcomeText}
              </span>
            )}
            {orgInfo?.org?.welcomeImage && orgInfo?.org?.welcomeKind === 2 && (
              <div className="mb-[7.5vw] size-full max-h-[150px] w-4/5 overflow-hidden">
                <img
                  src={makeStorageUrl(orgInfo?.org?.welcomeImage)}
                  className="size-full object-contain"
                />
              </div>
            )}
          </>
        )}
      </div>

      <div className="relative z-[1] mt-[-15vw]">
        <div className="mx-4 h-[300px] rounded-3xl bg-white px-6 py-4">
          <div className="border-b-text-4/20 mb-3 flex items-center border-b py-3">
            {Boolean(areaLabel) && (
              <>
                <div className="text-text-1 text-sm">{areaLabel}</div>
                {Boolean(areaLabel) && (
                  <div className="bg-text-4 mx-4 h-6 w-px" />
                )}
              </>
            )}

            <div className="flex flex-1 items-center" onClick={showSearchPopup}>
              <IconSearch className="mr-2 size-4" />
              {state.address && state.address?.length > 0 ? (
                <div className="text-text-1 line-clamp-1 flex-1 text-ellipsis break-all">
                  {state.address}
                </div>
              ) : (
                <div className="text-text-4 text-base">
                  搜索附近的地区或车站
                </div>
              )}
            </div>
          </div>

          <LodgingDates value={dateState} onChange={setDateState} />
          <TenantCount value={countState} onChange={handleCountChange} />

          <div className="mt-9 px-12">
            <button
              type="button"
              className="bg-resv-black w-full rounded-3xl py-3 text-center text-sm font-medium text-white"
              onClick={onSearch}
            >
              搜索
            </button>
          </div>
        </div>
        {(orgInfo?.org?.locations?.length ?? 0) > 0 && (
          <div className="mx-4 ">
            <div className="text-text-1 mb-3 mt-6 text-base font-medium">
              热门地点
            </div>
            <div className="flex flex-wrap justify-start">
              {orgInfo?.org?.locations.map((item) => (
                <div
                  key={item.name}
                  className="active:bg-easbnb-brand mb-3 mr-1.5 rounded-md border px-4 py-1.5 text-xs"
                  onClick={() => {
                    onChooseHotPlace(item);
                  }}
                >
                  <div className="text-sm">{item.name}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
