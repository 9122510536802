import React, { useMemo } from "react";
import {
  Form,
  type FormInstance,
  Input,
  NavBar,
  Picker,
  Toast,
} from "react-vant";
import { Helmet } from "react-helmet";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useRequest, useSetState } from "ahooks";
import { RoomService } from "@api/http_resv/room/room_srv";
import { OrderService } from "@api/http_resv/order/order_srv";
import type * as order_srv from "@api/http_resv/order/order_srv";
import { isUndefined, isNil } from "lodash-es";
import type * as common_type from "@api/http_resv/common/common_type";
import dayjs from "dayjs";
import { Loading } from "@/components/Loading";
import { PageLayout } from "@/components/layouts";
import { RoomCard, CheckInOutCard } from "../components";
import { Card } from "@/components/Card";
import CaretDownIcon from "@/assets/icons/caret_down.svg?react";
import TrumpetIcon from "@/assets/icons/trumpet.svg?react";
import { FixedBottomWrapper } from "@/components/FixedBottomWrapper";
import { genPageTitle } from "@/utils";

import { PATHS } from "@/constants/path";

import { stringify } from "@/lib/utils";
import { useUrlSearchState } from "@/hooks/useUrlSearchState";
import { getMoneyText } from "@/utils/money";
import { isEmail } from "@/utils/regex";
import { useUserInfoStore } from "@/stores/useUserInfoStore";
import { makeStorageUrl } from "@/utils/file";

const columns = [
  { text: "86", value: "86" },
  { text: "81", value: "81" },
];

interface MobileInputValue {
  countryCode: string;
  phoneNumber: string;
}

interface MobileInputProps {
  value?: MobileInputValue;
  onChange?: (value: MobileInputValue) => void;
}

// 自定义表单项
const MobileInput: React.FC<MobileInputProps> = ({
  value = { countryCode: "", phoneNumber: "" },
  onChange,
}) => {
  const trigger = (changedValue: Partial<MobileInputValue>) => {
    onChange?.({ ...value, ...changedValue });
  };

  const onPhoneNumberChange = (v: string) => {
    trigger({ phoneNumber: v });
  };

  const onCountryCodeChange = (v: string) => {
    trigger({ countryCode: v });
  };

  return (
    <>
      <Picker
        popup={true}
        value={value.countryCode}
        placeholder={false}
        columns={columns}
        onConfirm={onCountryCodeChange}
      >
        {(_, selectRow: any, actions) => {
          return (
            <div className="flex items-center">
              <div className="flex items-center" onClick={() => actions.open()}>
                <div className="text-text-1 text-sm">+{selectRow?.text}</div>
                <CaretDownIcon className="size-4" />
              </div>
              <Input
                value={value.phoneNumber}
                placeholder="请填写手机号"
                autoCapitalize="off"
                autoCorrect="off"
                type="tel"
                className="flex-1"
                onChange={onPhoneNumberChange}
              />
            </div>
          );
        }}
      </Picker>
    </>
  );
};

export const ContactInfoForm = ({ form }: { form: FormInstance }) => {
  const userInfo = useUserInfoStore((s) => s.userInfo);
  const [initValues] = useSetState<{
    name: string;
    email: string;
    mobile: string;
    countryCode: string;
    phoneNumber: string;
  }>({
    name: userInfo?.defaultTenant?.name || "",
    email: userInfo?.user?.email || "",
    mobile: userInfo?.defaultTenant?.phoneNumber || "",
    countryCode: userInfo?.defaultTenant?.countryCode || "86",
    phoneNumber: userInfo?.defaultTenant?.phoneNumber || "",
  });

  return (
    <Form form={form}>
      <Form.Item
        rules={[{ required: true, message: "请填写联系人姓名" }]}
        name="name"
        initialValue={initValues.name}
        label="姓名"
      >
        <Input placeholder="请填写联系人姓名" />
      </Form.Item>
      <Form.Item
        initialValue={initValues.email}
        rules={[
          { required: true, message: "请填写邮箱地址" },
          {
            message: "邮箱格式不正确",
            validator: (_, value, cb) => {
              if (!isEmail(value as string)) {
                return Promise.reject(new Error("邮箱格式不正确"));
              }
              return Promise.resolve("");
            },
            validateTrigger: "onBlur",
          },
        ]}
        name="email"
        label="邮箱地址"
      >
        <Input
          placeholder="请填写邮箱地址"
          autoCapitalize="off"
          autoCorrect="off"
          type="text"
        />
      </Form.Item>
      <Form.Item
        initialValue={{
          countryCode: initValues.countryCode,
          phoneNumber: initValues.phoneNumber,
        }}
        name="mobile"
        label="手机号"
      >
        <MobileInput />
      </Form.Item>
      <Form.Item name="remark" label="备注信息">
        <Input.TextArea placeholder="请填写备注信息" />
      </Form.Item>
    </Form>
  );
};

export const OrderConfirm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { id } = useParams<{ id?: string }>();
  const userInfo = useUserInfoStore((s) => s.userInfo);
  const fetchUserInfo = useUserInfoStore((s) => s.fetchUserInfo);

  const { priceQuery } = useUrlSearchState();
  const { startDate, endDate, adultCount } = priceQuery || {};

  const nights = useMemo(() => {
    if (!priceQuery) {
      return 1;
    }

    return dayjs(priceQuery.endDate as string).diff(
      priceQuery.startDate as string,
      "day",
    );
  }, [priceQuery]);

  const { data, loading } = useRequest(
    async () => {
      const resp = await RoomService.Get({
        id: id!,
        priceQuery: priceQuery as order_srv.BookReq["priceQuery"],
      });

      return resp.room;
    },
    { ready: !isUndefined(id) },
  );

  const { run } = useRequest(
    async (req: order_srv.BookReq) => {
      Toast.loading({
        message: "订单创建中，预计3到10秒",
        duration: 0,
        forbidClick: true,
        overlay: true,
      });
      const resp = await OrderService.Book(req);
      return resp;
    },
    {
      manual: true,
      onSuccess: async (resp) => {
        if (resp.isLogin) {
          await fetchUserInfo();
        }

        Toast.clear();
        const query = resp.order.needSignContract
          ? stringify({
              orderId: resp.order.id,
              signedOrderId: resp.order.signedOrderId,
              signAt: "",
            })
          : stringify({
              email: form.getFieldValue("email"),
              signedOrderId: resp.order.signedOrderId,
            });

        // 当前订单不需要签署协议时，直接跳转到支付
        const path = resp.order.needSignContract
          ? generatePath(`${PATHS.ORDER_AGREEMENT}/:id`, { id: resp.order.id })
          : generatePath(`${PATHS.PAY}/:id`, { id: resp.order.id });

        navigate(`${path}?${query}`, { replace: true });
      },
      onError(e) {
        Toast.clear();
        console.error(e);
        Toast({
          type: "fail",
          message: e.message,
        });
      },
    },
  );

  const goToPay = async () => {
    // 校验用户信息是否填写完善
    try {
      const values = await form.validateFields();
      const tenant: common_type.Tenant = {
        name: values?.name || "",
        email: values?.email || "",
        countryCode: values?.mobile?.countryCode || "",
        phoneNumber: values?.mobile?.phoneNumber || "",
      };

      const remark = values?.remark || "";

      if (!tenant.name || !tenant.email) {
        Toast({
          type: "fail",
          message: "联系人信息填写有误",
        });
        return;
      }

      if (!id || isUndefined(priceQuery) || isUndefined(data)) {
        Toast({
          type: "fail",
          message: "获取房间价格信息异常",
        });
        return;
      }

      run({
        roomId: id,
        tenant,
        remark,
        priceQuery: priceQuery as order_srv.BookReq["priceQuery"],
        checkPrice: data.quotedPrice!,
      });
    } catch (error) {
      console.error(error);
    }
  };

  if (!id) {
    return (
      <div className="flex h-[50vh] w-full items-center justify-center">
        <Helmet>
          <title>{genPageTitle("确认订单")}</title>
        </Helmet>
        <div className="text-text-4 text-sm">无法获取房间信息</div>
      </div>
    );
  }
  return (
    <PageLayout className="!pt-0">
      <Helmet>
        <title>{genPageTitle("确认订单")}</title>
      </Helmet>

      {loading && (
        <div className="flex h-1/2 w-full items-center justify-center">
          <Loading />
        </div>
      )}

      {!loading && data && (
        <div>
          <NavBar
            title="确认订单"
            leftText="返回"
            fixed={true}
            placeholder={true}
            zIndex={50}
            onClickLeft={() => {
              navigate(-1);
            }}
          />
          <div className="flex flex-col space-y-3 pt-3">
            <RoomCard
              bodyTopPadding={false}
              room={data.room}
              quotedPrice={data?.quotedPrice}
              imageUri={makeStorageUrl(data.images[0]?.uri || "")}
            />

            <CheckInOutCard
              startDate={startDate}
              endDate={endDate}
              adultCount={adultCount}
            />

            <Card title="联系人信息">
              {isNil(userInfo) && (
                <div className="mb-4 mt-2 flex items-center rounded-full bg-[#FDF6EF] py-1.5 pl-2 text-xs text-[#EC642B]">
                  <TrumpetIcon className="mr-1 size-4" />
                  <div>填写的邮箱将会作为您的登录账号查看订单使用。</div>
                </div>
              )}
              <ContactInfoForm form={form} />
            </Card>

            {data?.canBook && !isUndefined(priceQuery) && (
              <FixedBottomWrapper className=" flex w-full items-center justify-between bg-white px-6 py-3">
                <div className="flex flex-col">
                  {data.quotedPrice && (
                    <div className="font-DINPro text-danger text-2xl font-medium">
                      {getMoneyText(
                        data.quotedPrice.amount,
                        data.quotedPrice.currency,
                        { hidePlusSign: true },
                      )}
                    </div>
                  )}

                  {data.basePrice && data.quotedPrice && (
                    <div className="text-text-2 flex items-center justify-start space-x-2 text-xs">
                      {data.quotedPrice.amount !== data.basePrice.amount && (
                        <span className="line-through">
                          {getMoneyText(
                            data.basePrice.amount,
                            data.basePrice.currency,
                            { hideLabel: true, hidePlusSign: true },
                          )}
                        </span>
                      )}
                      <span>
                        均价
                        {getMoneyText(
                          data.quotedPrice.amount / nights,
                          data.quotedPrice.currency,
                          { hideLabel: true, hidePlusSign: true },
                        )}
                        /晚
                      </span>
                    </div>
                  )}

                  {data.lowestPrice && (
                    <div className="flex flex-row items-center">
                      <span className="font-DINPro text-danger mr-1 text-2xl font-medium">
                        {getMoneyText(
                          data.lowestPrice.amount,
                          data.lowestPrice.currency,
                          { hidePlusSign: true },
                        )}
                      </span>
                      <span className="text-text-2 flex flex-col items-center space-x-2 text-xs">
                        起/晚
                      </span>
                    </div>
                  )}
                </div>
                <button
                  type="button"
                  className="bg-resv-black rounded-full px-16 py-3 text-base font-medium text-white"
                  onClick={goToPay}
                >
                  预定
                </button>
              </FixedBottomWrapper>
            )}
          </div>
        </div>
      )}
    </PageLayout>
  );
};
