import dayjs from "dayjs";
import { isNaN } from "lodash-es";
import { useMemo } from "react";
import { Card } from "@/components/Card";
import PeopleIcon from "@/assets/icons/people.svg?react";
import { cn, toMMDD } from "@/lib/utils";
import { PLACEHOLDER_TEXT } from "@/constants/text";

interface CheckInOutProps extends React.HTMLAttributes<HTMLDivElement> {
  startDate?: string;
  endDate?: string;
  adultCount?: number;
}

export const CheckInOutCard = ({
  className,
  startDate,
  endDate,
  adultCount,
  ...props
}: CheckInOutProps) => {
  const nights = dayjs(endDate).diff(startDate, "day");
  const ignoreThisYear = useMemo(() => {
    const isThisYear = dayjs(endDate).isSame(dayjs(), "years");
    if (isThisYear) {
      const isSameYear = dayjs(startDate).isSame(dayjs(endDate), "years");
      return isSameYear && isThisYear;
    }

    return false;
  }, [startDate, endDate]);

  if (!startDate || !endDate) {
    return null;
  }

  return (
    <Card className={cn("flex justify-between", className)} {...props}>
      <div className="flex flex-col items-start space-y-1.5">
        <span className="text-text-666 text-xs">入住日期</span>
        <span className="text-base font-medium">
          {startDate
            ? toMMDD(startDate, { chinese: true, ignoreThisYear })
            : PLACEHOLDER_TEXT}
        </span>
      </div>

      <div className="flex flex-1 flex-col items-center space-y-1.5">
        <div className="flex items-center space-x-1">
          <PeopleIcon className="size-4" />
          <span className="text-text-3 text-xs">{adultCount}人</span>
        </div>

        <div className="border-easbnb-brand text-easbnb-brand rounded-full border border-solid bg-white px-3 py-1 text-xs">
          共{isNaN(nights) ? PLACEHOLDER_TEXT : nights}晚
        </div>
      </div>

      <div className="flex flex-col items-start space-y-1.5">
        <span className="text-text-666 text-xs">退房日期</span>
        <span className="text-base font-medium">
          {endDate
            ? toMMDD(endDate, { chinese: true, ignoreThisYear })
            : PLACEHOLDER_TEXT}
        </span>
      </div>
    </Card>
  );
};
