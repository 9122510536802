import { Helmet } from "react-helmet";
import { useRequest } from "ahooks";
import { useMemo } from "react";
import type * as common_type from "@api/http_resv/common/common_type";
import { EntityKind } from "@api/http_pms/const/const_enum";
import { NavBar, Toast, Image, ImagePreview } from "react-vant";
import clsx from "clsx";
import { CommonService } from "@api/http_resv/common/common_srv";
import { OrderService } from "@api/http_resv/order/order_srv";
import {
  useNavigate,
  useParams,
  useLocation,
  generatePath,
} from "react-router-dom";
import { isNil } from "lodash-es";
import { genAgreementCoverUrl, makeStorageUrl } from "@/utils/file";
import { FixedBottomWrapper } from "@/components/FixedBottomWrapper";
import { Card } from "@/components/Card";
import { parseSearchStr, stringify } from "@/lib/utils";
import { PATHS } from "@/constants/path";
import { useUrlSearchState } from "@/hooks/useUrlSearchState";

import { genPageTitle } from "@/utils";

export const OrderAgreement = () => {
  const navigate = useNavigate();

  const params = useParams<{ id: string }>();
  const id = params.id!;

  const location = useLocation();
  const { queryObj } = useUrlSearchState();

  const { orderId, signedOrderId, signedAt } = parseSearchStr(location.search);

  const isSigned = useMemo(() => !isNil(signedAt), [signedAt]);

  const { data, loading } = useRequest(async () => {
    const resp = await CommonService.ListImageByEntity({
      entityInfo: {
        entityKind: EntityKind.ResvContract,
        relationId: orderId as string,
        relationSubId: "",
      },
    });

    const result = {
      images: resp.images
        .filter((item) => item.partition === 2)
        .sort((a, b) => a.rank - b.rank),
      downloadUrl: resp.images.filter((item) => item.partition === 1)[0]?.uri,
    };

    return result;
  });
  const downLoadAgreement = () => {
    if (!data?.downloadUrl) {
      return;
    }
    window.open(makeStorageUrl(data.downloadUrl));
  };

  const onSignAgreement = async () => {
    const resp = await OrderService.SignContract({
      signedOrderId: signedOrderId as string,
    });

    if (resp) {
      const path = generatePath(`${PATHS.ORDER}/:id`, {
        id,
      });
      const query = stringify({
        signedOrderId: queryObj.signedOrderId,
      });
      navigate(`${path}?${query}`, { replace: true });
    } else {
      Toast.fail("签署失败,请重试");
    }
  };

  const onCancelAgreement = () => {
    const path = generatePath(`${PATHS.ORDER}/:id`, { id });
    navigate(path, { replace: true });
  };

  if (loading) {
    Toast.loading({
      message: "加载中...",
      forbidClick: true,
      overlay: true,
    });
  }
  return (
    <div className=" p-4">
      <Helmet>
        <title>{genPageTitle("协议签署")}</title>
      </Helmet>
      <NavBar
        title="协议签署"
        leftText="返回"
        fixed={true}
        placeholder={true}
        zIndex={50}
        onClickLeft={() => {
          onCancelAgreement();
        }}
      />
      <div className="flex flex-col space-y-3">
        {!isSigned && (
          <Card>
            <div className="mb-3 flex items-center space-x-6">
              <span className="text-text-1 text-base font-medium">
                请您先阅读协议内容无误后确认并签字
              </span>
            </div>
            <div className="text-text-666 text-sm">
              若有任何疑问请随时与我们联系
            </div>
          </Card>
        )}
        <Card>
          {data?.images?.map((item: common_type.Image, index) => (
            <Image
              key={item.rank}
              src={genAgreementCoverUrl(item.uri)}
              fit="cover"
              lazyload={true}
              onClick={() => {
                ImagePreview.open({
                  images: data.images.map((image) =>
                    genAgreementCoverUrl(image.uri),
                  ),
                  startPosition: index,
                });
              }}
            />
          ))}
        </Card>

        {!isSigned && (
          <FixedBottomWrapper className="flex w-full items-center justify-between bg-white px-10 py-3">
            <button
              onClick={onCancelAgreement}
              type="button"
              className="text-text-1 rounded-full border border-[#B8BCCB] bg-white px-10 py-3 text-base font-medium"
            >
              取消
            </button>
            <button
              type="button"
              onClick={onSignAgreement}
              className={clsx(
                "bg-resv-black rounded-full px-10 py-3 text-base font-medium text-white",
              )}
            >
              确认并同意
            </button>
          </FixedBottomWrapper>
        )}

        <button
          type="button"
          className={clsx(
            " fixed bottom-28 left-1/2 z-10 h-12 w-40 -translate-x-1/2 rounded-full  border  px-10 py-3 text-base font-medium shadow-lg",
            isSigned
              ? "bg-resv-black text-white"
              : "text-text-1 border-[#B8BCCB] bg-white",
          )}
          onClick={downLoadAgreement}
        >
          下载协议
        </button>
      </div>
    </div>
  );
};
