import { Calendar } from "react-vant";
import { styled } from "styled-components";
import alreadyBookedUrl from "@/assets/icons/already_booked.svg?url";
import canBookingCheckInUrl from "@/assets/icons/canbooking_checkin.svg?url";
import canBookingCheckOutUrl from "@/assets/icons/canbooking_checkout.svg?url";
import cantBookingCheckInUrl from "@/assets/icons/cantbooking_checkin.svg?url";
import cantBookingCheckOutUrl from "@/assets/icons/cantbooking_checkout.svg?url";

export const CalendarCssWrapper = styled(Calendar)<{ $canNotBook?: boolean }>`
  .alreadyBooked {
    background-image: url("${alreadyBookedUrl}");
    background-repeat: no-repeat;
    background-position: right top;
  }

  .isHotelIndustryWeekend {
    color: #ec642b;
  }

  .checkIn {
    background-image: ${(c) =>
      c.$canNotBook
        ? `url("${cantBookingCheckInUrl}")`
        : `url("${canBookingCheckInUrl}")`} !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-color: ${(c) =>
      c.$canNotBook ? `#ec642b1a` : `#6f9f0a1a`} !important;
    color: transparent !important;
  }
  .checkOut {
    background-image: ${(c) =>
      c.$canNotBook
        ? `url("${cantBookingCheckOutUrl}")`
        : `url("${canBookingCheckOutUrl}")`} !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-color: ${(c) =>
      c.$canNotBook ? `#ec642b1a` : `#6f9f0a1a`} !important;
    color: transparent !important;
  }
`;
