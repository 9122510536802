import React from "react";
import type * as room_type from "@api/http_resv/room/room_type";
import type * as common_type from "@api/http_pms/common/common_type";
import { Image } from "react-vant";
import { generatePath, useNavigate } from "react-router-dom";
import LocationIcon from "@/assets/icons/location.svg?react";
import { cn } from "@/lib/utils";
import { Card } from "@/components/Card";
import { PLACEHOLDER_TEXT } from "@/constants/text";
import { getMoneyText } from "@/utils/money";
import { getRoomInfo } from "@/utils/roomInfo";
import { PATHS } from "@/constants/path";

interface RoomCardProps extends React.HTMLAttributes<HTMLDivElement> {
  extra?: React.ReactNode;
  header?: React.ReactNode;
  bodyTopPadding?: boolean;
  bodyBottomPadding?: boolean;
  /* 是否禁用跳转功能 */
  disableLink?: boolean;
  imageUri: string;
  room: room_type.Room;
  quotedPrice?: common_type.CurrencyAmount;
}

export const RoomCardHeaderWrapper = ({
  children,
}: React.PropsWithChildren) => {
  return (
    <div className="flex justify-between border-b border-dashed border-b-[#e5e5e55] pb-2">
      {children}
    </div>
  );
};

export const RoomCard = ({
  extra,
  header,
  className,
  bodyTopPadding = true,
  bodyBottomPadding = true,
  disableLink = false,
  room,
  quotedPrice,
  imageUri,
  ...props
}: RoomCardProps) => {
  const roomInfoText = getRoomInfo({
    area: room.roomSize,
    bed: room.singleBedCount,
    doubleBed: room.doubleBedCount,
  });
  const navigate = useNavigate();

  const onGoToRoomDetail: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (disableLink) return;
    navigate(generatePath(`${PATHS.ROOM}/:id`, { id: room.id }), {
      replace: true,
    });
    // 在订单列表页点击列表项中的房间卡片，会触发房间详情页的跳转
    // 在订单列表页点击列表项，会触发订单详情页的跳转
    // 此时需要阻止事件冒泡，防止点击房间卡片的时候触发订单详情页的跳转
    e.stopPropagation();
  };

  return (
    <Card className={cn("flex flex-col", className)} {...props}>
      {header}

      <div
        className={cn("flex", {
          "pt-3": bodyTopPadding,
          "pb-3": bodyBottomPadding,
        })}
        onClick={onGoToRoomDetail}
      >
        <div className="mr-3 size-[84px] overflow-hidden rounded-lg">
          <Image src={imageUri} alt="房间图片" fit="cover" />
        </div>
        <div className="flex flex-1 flex-col">
          <div className="text-text-1 mb-1 line-clamp-1 text-ellipsis text-base font-medium">
            {room.title || PLACEHOLDER_TEXT}
          </div>
          <div className="mb-1.5">
            <span className="text-text-666 text-sm">{roomInfoText}</span>
          </div>
          <div className="mb-1.5 flex items-center justify-between">
            <span className="text-easbnb-brand text-sm">
              可住{room.personCapacity || PLACEHOLDER_TEXT}人
            </span>
            <span className="font-DINPro text-text-2 text-sm">
              {quotedPrice && (
                <div>
                  {getMoneyText(quotedPrice.amount, quotedPrice.currency, {
                    hidePlusSign: true,
                  })}
                </div>
              )}
            </span>
          </div>
        </div>
      </div>

      <div className="flex items-center space-x-2 border-t border-dashed border-t-[#e5e5e55] pt-3.5">
        <LocationIcon className="size-4" />
        <div className="text-text-666 text-sm">
          {room.address || PLACEHOLDER_TEXT}
        </div>
      </div>

      {extra}
    </Card>
  );
};
