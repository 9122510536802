import { cn } from "@/lib/utils";

interface PageLayoutProps extends React.HTMLAttributes<HTMLDivElement> {}

export const PageLayout = ({ children, className }: PageLayoutProps) => {
  return (
    <div
      className={cn(
        "flex h-screen flex-col overflow-y-auto px-4 py-3 pb-56",
        className,
      )}
    >
      {children}
    </div>
  );
};
