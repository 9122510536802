import { isNil } from "lodash-es";
import { useMemo } from "react";
import clsx from "clsx";
import dayjs from "dayjs";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { stringify } from "@/lib/utils";

import SingedIcon from "@/assets/icons/singed.svg?react";
import UnsignedIcon from "@/assets/icons/unsinged.svg?react";
import { PATHS } from "@/constants/path";
import { Card } from "@/components/Card";

interface AgreementCardProps {
  signedAt?: string;
  contractName: string;
  orderId: string;
  signedOrderId: string;
}

export const AgreementCard = ({
  signedAt,
  contractName,
  orderId,
  signedOrderId,
  ...props
}: AgreementCardProps) => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const id = params.id!;

  const isSigned = useMemo(() => !isNil(signedAt), [signedAt]);
  const goAgreement = () => {
    const query = stringify({
      orderId,
      signedOrderId,
      signedAt,
      contractName,
    });
    const path = generatePath(`${PATHS.ORDER_AGREEMENT}/:id`, { id });
    navigate(`${path}?${query}`, { replace: true });
  };
  return (
    <Card className="p-0" {...props}>
      <div
        className={clsx(
          "flex h-9 items-center justify-between rounded-t-xl pl-3 pr-4",
          isSigned ? "bg-[#F0F5E6]" : "bg-[#FDF6EF]",
        )}
        onClick={goAgreement}
      >
        <div className="flex items-center space-x-2">
          {isSigned ? <SingedIcon /> : <UnsignedIcon />}
          <span className="text-text-666 text-sm font-medium">短租协议</span>
        </div>
        <div
          className={clsx("text-sm", isSigned ? "text-text-3" : "text-alert")}
        >
          {isSigned ? "查看" : "去签署"} &gt;
        </div>
      </div>
      <div className="m-3 flex h-12 flex-col justify-between">
        <div className="text-base font-medium">
          <span>{contractName}</span>
        </div>
        <div className="text-text-666 whitespace-pre-line text-sm font-normal">
          签署日期：
          {isSigned
            ? dayjs.unix(Number(signedAt)).format("YYYY-MM-DD HH:mm:ss")
            : "未签署"}
        </div>
      </div>
    </Card>
  );
};
