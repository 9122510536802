import { useState } from "react";
import { Dialog, NavBar, Toast } from "react-vant";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useCountDown, useRequest } from "ahooks";
import { OrderService } from "@api/http_resv/order/order_srv";
import type * as order_srv from "@api/http_resv/order/order_srv";
import { type PayChannel } from "@api/http_resv/org/org_type";
import { isUndefined, isEmpty } from "lodash-es";
import clsx from "clsx";
import { Loading } from "@/components/Loading";
import { PageLayout } from "@/components/layouts";
import { Card } from "@/components/Card";
import { useOrgInfoStore } from "@/stores/useOrgInfoStore";
import { PayChannels } from "./components/PayChannels";
import { FixedBottomWrapper } from "@/components/FixedBottomWrapper";
import { genPageTitle } from "@/utils";
import { isInWeChat } from "@/utils/environment";
import { getMoneyText } from "@/utils/money";

import { PATHS } from "@/constants/path";
import { getOrderStatus, OrderStatusEnum } from "@/utils/orderStatus";
import { useUrlSearchState } from "@/hooks/useUrlSearchState";
import payLinkRedirectPage from "@/assets/html/pay_link_redirect_page.html?url";
import { getResvCommonUrl } from "@/utils/url";

export const Pay = () => {
  const { queryObj } = useUrlSearchState();
  const navigate = useNavigate();
  const inWechat = isInWeChat();
  const [pay, setPay] = useState<PayChannel | Record<string, never>>({});
  const orgInfo = useOrgInfoStore((s) => s.data);
  const cantPay = isEmpty(orgInfo?.org?.payChannels || []) || isEmpty(pay);

  const { id } = useParams<{ id?: string }>();

  const { data, loading, run } = useRequest(
    async () => {
      const resp = await OrderService.Get({
        id: id!,
      });
      const countDownDate = resp.order.order.isCancelled
        ? undefined
        : Math.max(
            Number(resp?.order.order.cancelledAt) * 1000,
            Date.now() + 5000,
          );

      return { ...resp.order, countDownDate };
    },
    {
      ready: !isUndefined(id),
      onSuccess: (resp) => {
        const orderStatus = getOrderStatus({
          dateStart: resp.order.startDate,
          dateEnd: resp.order.endDate,
          paidAt: resp.order.paidAt,
          isCancelled: resp.order.isCancelled,
        });
        if (orderStatus !== OrderStatusEnum.Unpaid) {
          const orderDetailPath = generatePath(`${PATHS.ORDER}/:id`, {
            id: resp.order.id,
          });
          navigate(orderDetailPath, { replace: true });
        }
      },
    },
  );

  const { run: payOrder } = useRequest(
    async (req: order_srv.PayReq) => {
      const resp = await OrderService.Pay(req);

      return resp;
    },
    {
      manual: true,
      onBefore: () => {
        Toast.loading({
          message: "支付中...",
          duration: 0,
        });
      },
      onSuccess(resp) {
        // Toast.clear();

        if (!resp.endpoint) {
          Toast.fail({
            message: "获取支付链接失败",
            duration: 2000,
          });
          return;
        }
        // 打开微信支付链接，进行微信支付
        window.location.href = `${getResvCommonUrl()}${payLinkRedirectPage}?open=${encodeURIComponent(resp.endpoint)}}`;
        // // 跳转到支付结果页面
        const resultPage = generatePath(`${PATHS.PAY_RESULT}/:id`, { id: id! });
        navigate(resultPage, {
          replace: true,
        });
      },
      onFinally() {},
    },
  );

  const [, formattedRes] = useCountDown({
    targetDate: data?.countDownDate,
    onEnd: () => {
      if (!data?.order?.isCancelled) {
        run();
      }
    },
  });

  // const checkReadyToPay = async () => {
  //   // 校验用户信息是否填写完善
  //   try {
  //     const values = await form.validateFields();
  //     const expiryMonth = values.expireDate.expiryMonth;
  //     const expiryYear = values.expireDate.expiryYear;
  //     const creditCard: order_type.CreditCard = {
  //       name: values?.name || "",
  //       number: values?.number || "",
  //       cvv: values?.cvv || "",
  //       expiryMonth,
  //       expiryYear,
  //     };

  //     if (Object.values(creditCard).some((v) => !v)) {
  //       Toast({
  //         type: "fail",
  //         message: "信用卡支付信息填写有误",
  //       });
  //     }

  //     Toast.loading({
  //       message: "加载中...",
  //       duration: 0,
  //       forbidClick: true,
  //       overlay: true,
  //     });

  //     const resp = await payAsync({
  //       orderId: id!,
  //       payKind: pay,
  //       creditCard,
  //     });
  //     // status: pending-支付中, awaiting-等待, successful-成功, failed-失败, error-错误, authorized-授权, canceled-取消

  //     // 检查是否支付完成，如果返回的状态为 pending，则表示还未完成
  //     // 需要轮询调用 CheckPaid 接口
  //     if (resp.status === "pending") {
  //       checkPaid(id!);
  //       return;
  //     }

  //     // 支付成功
  //     if (resp.status === "successful") {
  //       Toast.clear();
  //       void NiceModal.show(PayOrderDetailPopup, {
  //         orderInDetail: data,
  //         searchQuery: location.search,
  //       });

  //       return;
  //     }

  //     Toast.fail(`支付失败，请重新支付`);
  //   } catch (error) {
  //     console.error(error);
  //     Toast.clear();
  //     Toast.fail(`支付失败: ${error}`);
  //   }
  // };

  const goToPay = async () => {
    if (cantPay) {
      return;
    }

    payOrder({
      signedOrderId: queryObj.signedOrderId as string,
      payChanel: pay.id,
      inWechat,
      redirectUrl: `${window.location.origin}${generatePath(`${PATHS.PAY_RESULT}/:id`, { id: id! })}`,
    });
  };

  if (!id) {
    return (
      <div className="flex h-[50vh] w-full items-center justify-center">
        <Helmet>
          <title>{genPageTitle("支付方式")}</title>
        </Helmet>
        <div className="text-text-4 text-sm">无法获取订单支付信息</div>
      </div>
    );
  }

  return (
    <PageLayout>
      <Helmet>
        <title>{genPageTitle("支付方式")}</title>
      </Helmet>
      {loading && (
        <div className="flex h-1/2 w-full items-center justify-center">
          <Loading />
        </div>
      )}

      {!loading && data && (
        <div>
          <NavBar
            title="确认支付"
            leftText="返回"
            fixed={true}
            placeholder={true}
            zIndex={50}
            onClickLeft={() => {
              Dialog.confirm({
                title: "确认放弃支付吗",
                confirmButtonText: "继续支付",
                confirmButtonColor: "var(--wd-color-easbnb-brand)",
                cancelButtonText: "放弃",
              })
                .then(() => {})
                .catch(() => {
                  navigate(PATHS.ORDER, { replace: true });
                });
            }}
          />
          <Card className="mb-3 flex flex-col items-center space-y-4 rounded-2xl">
            <div className="text-text-2 text-sm">实付金额</div>
            <div className="font-DINPro text-danger text-2xl font-medium">
              {getMoneyText(
                data.order.price.amount,
                data.order.price.currency,
                { hidePlusSign: true },
              )}
            </div>
            <div className="text-text-2 pb-5 text-sm">
              剩余支付时间 {formattedRes.minutes.toString().padStart(2, "0")}分
              {formattedRes.seconds.toString().padStart(2, "0")}秒
            </div>
          </Card>

          <PayChannels
            payChannels={orgInfo?.org?.payChannels || []}
            value={pay}
            onChange={setPay}
          />

          <FixedBottomWrapper className="flex w-full items-center justify-center bg-white py-3">
            <button
              type="button"
              className={clsx(
                "rounded-full px-20 py-3 text-base font-medium text-white",
                cantPay ? "bg-text-2" : "bg-black",
              )}
              onClick={goToPay}
            >
              确认支付
            </button>
          </FixedBottomWrapper>
        </div>
      )}
    </PageLayout>
  );
};
