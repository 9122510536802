import { type FC, useRef, useState } from "react";
import { Image, Swiper, type SwiperInstance } from "react-vant";
import {
  useNavigate,
  useSearchParams,
  generatePath,
  useParams,
} from "react-router-dom";
import type * as common_type from "@api/http_pms/common/common_type";
import clsx from "clsx";
import NoPhoto from "@/assets/icons/no_photo.svg?react";

import { PATHS } from "@/constants/path";
import { useSwiperData } from "@/hooks/useSwiperData";
import { genBigRoomSwiperCoverUrl } from "@/utils/file";

export interface InitImageProps {
  uri: string;
  rank: number;
  partition: common_type.PartitionKind;
  index?: number;
}

export interface RoomSwiperProps {
  value: InitImageProps[];
}

export interface ImagesGroupItem {
  label: string;
  name: string;
  images: InitImageProps[];
}

export const RoomSwiper: FC<RoomSwiperProps> = ({ value }) => {
  const swiperRef = useRef<SwiperInstance>(null);

  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams.entries());
  const { id } = useParams<{ id?: string }>();

  const swiperData = useSwiperData(value);

  const handleSwiperTabChange = (
    imgGroup: ImagesGroupItem,
    swiperTabIdx: number,
  ) => {
    setActiveTab(swiperTabIdx);

    swiperRef.current?.swipeTo(imgGroup.images?.[0]?.index ?? 0);
  };

  const handleSwiperChange = (swiperIndex: number) => {
    const active = swiperData.findIndex((swiper) =>
      swiper.images?.some((item) => item.index === swiperIndex),
    );

    setActiveTab(active);
  };

  const onRoomPhoto = () => {
    const path = generatePath(`${PATHS.ROOM_PHOTO}/:id`, { id: id! });

    navigate(`${path}`, {
      state: {
        data: value,
        params,
      },
      replace: true,
    });
  };

  if (!swiperData.length) {
    return (
      <div className="flex h-[300px] w-full items-center justify-center bg-[#F2F0F4]">
        <NoPhoto />
        <span className="text-text-3 absolute bottom-1/4 translate-y-1/2 text-sm">
          暂无图片
        </span>
      </div>
    );
  }

  return (
    // todo 图片能懒加载么？ 一下子并发这么多图片，服务器流量吃不消
    <Swiper
      className="h-[300px] w-full"
      ref={swiperRef}
      onChange={handleSwiperChange}
      autoplay={10000}
      indicator={(total, current) => (
        <>
          <div className="absolute right-4 top-4 rounded-full bg-black/45 px-2 py-1 text-xs text-white">
            {current + 1}/{total}
          </div>
          <div className="absolute bottom-3 left-1/2 flex -translate-x-1/2 items-center justify-between space-x-1 rounded-full bg-black/45 text-xs text-white">
            {swiperData.map((item, index) => (
              <div
                key={item.name}
                className={clsx(
                  "mx-1 my-0.5 w-12 px-2 py-1",
                  activeTab === index &&
                    "flex items-center justify-center overflow-hidden rounded-full bg-white text-black",
                )}
                onClick={() =>
                  handleSwiperTabChange(item as ImagesGroupItem, index)
                }
              >
                {item.name}
              </div>
            ))}

            <div className="flex items-center justify-center">
              <b className="mr-3 text-white/45">|</b>
              <div className="w-12" onClick={onRoomPhoto}>
                相册 &gt;
              </div>
            </div>
          </div>
        </>
      )}
    >
      {value.map((item) => (
        <Swiper.Item key={item.uri}>
          <div className="h-[300px] w-full">
            <Image
              lazyload={true}
              src={genBigRoomSwiperCoverUrl(item.uri)}
              fit="cover"
            />
          </div>
        </Swiper.Item>
      ))}
    </Swiper>
  );
};
