import React from "react";
import { cn } from "@/lib/utils";
import { useSafeAreaInsetBottom } from "@/hooks/useSafeAreaInsetBottom";

interface FixedBottomWrapperProps
  extends React.HTMLAttributes<HTMLDivElement> {}

export const FixedBottomWrapper = ({
  children,
  className,
  ...props
}: FixedBottomWrapperProps) => {
  const safeAreaInsetBottom = useSafeAreaInsetBottom();

  return (
    <div
      className={cn(
        "fixed inset-x-0 bottom-0 mx-auto max-w-[750px]",
        safeAreaInsetBottom > 0 ? "pb-safe-area-bottom" : "pb-6",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};
