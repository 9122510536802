import TabBarHomeIcon from "@/assets/icons/tab_bar_home.svg?react";
import TabBarOrderIcon from "@/assets/icons/tab_bar_order.svg?react";
import TabBarMeIcon from "@/assets/icons/tab_bar_me.svg?react";
import TabBarHomeSelectedIcon from "@/assets/icons/tab_bar_home_selected.svg?react";
import TabBarOrderSelectedIcon from "@/assets/icons/tab_bar_order_selected.svg?react";
import TabBarMeSelectedIcon from "@/assets/icons/tab_bar_me_selected.svg?react";

interface BottomNavigationBarItemProps {
  label: string;
  icon: React.ReactNode;
  selectedIcon: React.ReactNode;
  path: string;
  needLogin?: boolean;
}

export const BOTTOM_NAVIGATION_BAR_ITEMS: BottomNavigationBarItemProps[] = [
  {
    path: "/",
    label: "首页",
    icon: <TabBarHomeIcon className="size-5" />,
    selectedIcon: <TabBarHomeSelectedIcon className="size-5" />,
  },
  {
    path: "/order",
    label: "订单",
    icon: <TabBarOrderIcon className="size-5" />,
    needLogin: true,
    selectedIcon: <TabBarOrderSelectedIcon className="size-5" />,
  },
  {
    path: "/me",
    label: "我的",
    icon: <TabBarMeIcon className="size-5" />,
    selectedIcon: <TabBarMeSelectedIcon className="size-5" />,
  },
];
