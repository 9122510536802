import ReactDOM from "react-dom/client";
import { subscribe } from "pubsub-js";
import { Toast } from "react-vant";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import App from "./pages/App.tsx";
import "./index.css";
import { useUserInfoStore } from "./stores/useUserInfoStore.ts";

dayjs.extend(utc);
dayjs.extend(timezone);

subscribe("webapi_net_err", (_, error: string) => {
  Toast.fail(error);
});

subscribe("webapi_status_401", (_, error: string) => {
  // 401 状态，移除store中的用户信息
  useUserInfoStore.getState().removeUserInfo();
});

ReactDOM.createRoot(document.getElementById("root")!).render(<App />);
