import { Helmet } from "react-helmet";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { OrderService } from "@api/http_resv/order/order_srv";
import { useRequest } from "ahooks";
import { isNil, isUndefined } from "lodash-es";
import { Dialog } from "react-vant";
import { SignService } from "@api/http_resv/sign/sign_srv";
import { Loading } from "@/components/Loading";
import { genPageTitle } from "@/utils";
import { PageLayout } from "@/components/layouts";
import SuccessIcon from "@/assets/icons/success.svg?react";
import ErrorIcon from "@/assets/icons/error.svg?react";
import { useUserInfoStore } from "@/stores/useUserInfoStore";
import { getMoneyText } from "@/utils/money";
import { PATHS } from "@/constants/path";
import { stringify } from "@/lib/utils";

export const PayResult = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();
  const userInfo = useUserInfoStore((s) => s.userInfo);
  const removeUserInfo = useUserInfoStore((s) => s.removeUserInfo);
  const [currentEmail, setCurrentEmail] = useState(() => userInfo?.user.email);

  const { data, loading: getOrderLoading } = useRequest(
    async () => {
      const resp = await OrderService.Get({
        id: id!,
      });

      return resp.order;
    },
    {
      ready: !isUndefined(id),
    },
  );

  const {
    data: checkPaidResp,
    loading: checkPaidLoading,
    cancel: cancelPolling,
  } = useRequest(
    async () => {
      const resp = await OrderService.CheckPaid({
        id: id!,
      });

      return resp;
    },
    {
      ready: !isUndefined(id),
      pollingInterval: 3000,
      onSuccess: (resp) => {
        setCurrentEmail(resp.email);
        if (resp.status === "awaiting") {
          return;
        }
        cancelPolling();
      },
    },
  );

  // awaiting-等待, successful-成功, error-错误
  const paidSuccess = checkPaidResp?.status === "successful";
  const paidError = checkPaidResp?.status === "error";
  const paidAwaiting = checkPaidResp?.status === "awaiting";
  // 不是上面3种状态的其它状态
  const paidOtherStatus = !paidSuccess && !paidError && !paidAwaiting;
  const isLogin = checkPaidResp?.isLogin || false;

  const onGoToLogin = () => {
    if (checkPaidResp?.email !== currentEmail) {
      Dialog.confirm({
        title: "已登录用户和当前订单所属邮箱不一致，确定继续登录吗?",
        confirmButtonText: "继续登录",
        confirmButtonColor: "var(--wd-color-easbnb-brand)",
        cancelButtonText: "回首页",
      })
        .then(async () => {
          removeUserInfo();
          await SignService.SignOut({});
          const query = currentEmail ? `?email=${currentEmail}` : "";
          const loginPath = generatePath(`${PATHS.LOGIN}${query}`);
          navigate(loginPath, { replace: true });
        })
        .catch(() => {
          navigate(PATHS.HOME, { replace: true });
        });
      return;
    }

    const query = currentEmail ? `?email=${currentEmail}` : "";
    const loginPath = generatePath(`${PATHS.LOGIN}${query}`);
    navigate(loginPath, { replace: true });
  };

  if (!id) {
    return (
      <div className="flex h-[50vh] w-full items-center justify-center">
        <Helmet>
          <title>{genPageTitle("支付结果")}</title>
        </Helmet>
        <div className="text-text-4 text-sm">无法获取支付结果信息</div>
      </div>
    );
  }

  if (getOrderLoading || checkPaidLoading || paidAwaiting || paidOtherStatus) {
    return (
      <div className="flex h-[50vh] w-full items-center justify-center">
        <Helmet>
          <title>{genPageTitle("支付结果")}</title>
        </Helmet>
        <div className="text-text-4 text-sm">
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <PageLayout>
      <Helmet>
        <title>{genPageTitle("支付结果")}</title>
      </Helmet>
      <div className="flex flex-col items-center">
        {!isNil(data) && paidSuccess && (
          <>
            <div className="mb-6 mt-[70px]">
              <SuccessIcon className="size-[52px]" />
            </div>
            <span className="text-text-1 mb-3 text-2xl font-medium">
              支付成功
            </span>
            <div className="flex items-center">
              <span className="text-text-3 text-sm">实付金额：</span>
              <span className="font-DINPro text-xl font-medium text-[#FF1010]">
                {getMoneyText(
                  data.order.price.amount,
                  data.order.price.currency,
                  {
                    hidePlusSign: true,
                  },
                )}
              </span>
            </div>
          </>
        )}
        {paidError && (
          <>
            <div className="mb-6 mt-[70px]">
              <ErrorIcon className="size-[52px]" />
            </div>
            <span className="text-text-1 mb-3 text-2xl font-medium">
              支付失败
            </span>
          </>
        )}
        {renderSubDescription()}
        {renderFooterButton()}
      </div>
    </PageLayout>
  );

  function renderSubDescription() {
    if (isNil(data) || !paidSuccess) {
      return null;
    }

    if (!isLogin) {
      return (
        <div className="text-text-3 mt-2 text-sm">
          请登录{currentEmail}，查看订单详情
        </div>
      );
    }
  }

  function renderFooterButton() {
    if (!paidSuccess) {
      if (!isLogin) {
        return null;
      }
      return (
        <button
          type="button"
          className="bg-resv-black mt-[70px] rounded-full px-16 py-3 text-base font-medium text-white"
          onClick={() => {
            const query = stringify({
              signedOrderId: data?.order.signedOrderId,
            });
            const path = generatePath(`${PATHS.PAY}/:id`, {
              id: id || "",
            });

            navigate(`${path}?${query}`, { replace: true });
          }}
        >
          返回支付
        </button>
      );
    }

    if (isLogin) {
      return (
        <button
          type="button"
          className="bg-resv-black mt-[70px] rounded-full px-16 py-3 text-base font-medium text-white"
          onClick={() => {
            navigate(
              generatePath(`${PATHS.ORDER}/:id`, {
                id: id || "",
              }),
              { replace: true },
            );
          }}
        >
          查看订单
        </button>
      );
    }
    return (
      <button
        type="button"
        className="bg-resv-black mt-[70px] rounded-full px-16 py-3 text-base font-medium text-white"
        onClick={onGoToLogin}
      >
        登录后查看订单
      </button>
    );
  }
};
