import { create } from "zustand";
import type * as user_srv from "@api/http_resv/user/user_srv";
import { UserService } from "@api/http_resv/user/user_srv";

interface State {
  userInfo?: user_srv.InfoResp | null;
}

interface Action {
  setUserInfo: (userInfo: user_srv.InfoResp) => void;
  removeUserInfo: () => void;
  fetchUserInfo: () => Promise<void>;
}

export const useUserInfoStore = create<State & Action>((set) => ({
  setUserInfo: (userInfo) => set({ userInfo }),
  removeUserInfo: () => set({ userInfo: null }),
  fetchUserInfo: async () => {
    const resp = await UserService.Info({});
    set({ userInfo: resp });
  },
}));
