import { Helmet } from "react-helmet";
import { useCountDown, useRequest, useSetState } from "ahooks";
import type * as sign_srv from "@api/http_resv/sign/sign_srv";
import { isString, isUndefined } from "lodash-es";
import { SignService } from "@api/http_resv/sign/sign_srv";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Toast } from "react-vant";
import dayjs from "dayjs";
import { UserService } from "@api/http_resv/user/user_srv";
import LoginBannerImg from "@/assets/images/login_banner.svg?react";
import { genPageTitle } from "@/utils";
import { cn, parseSearchStr } from "@/lib/utils";
import { isEmail } from "@/utils/regex";
import { PATHS } from "@/constants/path";
import { useUserInfoStore } from "@/stores/useUserInfoStore";

const parseToLoginQuery = (search: string) => {
  const obj = parseSearchStr(search);
  const emailStr = obj.email;

  let email: string | undefined;

  if (isString(emailStr) && isEmail(emailStr)) {
    email = emailStr;
  }

  return {
    email,
  };
};

export const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { email } = parseToLoginQuery(location.search);
  const [state, setState] = useSetState<sign_srv.SignInByEmailReq>({
    email: email || "",
    code: "",
  });
  const [emailError, setEmailError] = useState("");
  const [codeError, setCodeError] = useState("");

  const setUserInfo = useUserInfoStore((s) => s.setUserInfo);

  const { run: sendEmail } = useRequest(
    async () => {
      const resp = await SignService.SendEmail({
        email: state.email,
      });

      return resp;
    },
    {
      manual: true,
      onSuccess: () => {
        Toast({ message: "验证码已发送", duration: 1000 });
        setTargetDate(dayjs().add(1, "minute").toDate());
      },
    },
  );

  const { run: signInByEmail } = useRequest(
    async () => {
      await SignService.SignInByEmail(state);
      const resp = await UserService.Info({});

      return resp;
    },
    {
      manual: true,
      onSuccess: (resp) => {
        Toast.clear();
        setUserInfo(resp);
        navigate(PATHS.ME, { replace: true });
      },
      onError: (e) => {
        console.error(e);
      },
    },
  );

  const [targetDate, setTargetDate] = useState<Date>();

  const [countdown] = useCountDown({
    targetDate,
    onEnd: () => {
      setTargetDate(undefined);
    },
  });

  const onSendEmail = async () => {
    if (!state.email) {
      setEmailError("请输入邮箱");
      return;
    }
    if (!isEmail(state.email)) {
      setEmailError("请输入正确的邮箱");
      return;
    }
    Toast.loading({
      message: "加载中...",
      duration: 0,
      forbidClick: true,
      overlay: true,
    });

    sendEmail();
  };

  const onLogin = () => {
    if (!state.email) {
      setEmailError("请输入邮箱");
      return;
    }
    if (!isEmail(state.email)) {
      setEmailError("请输入正确的邮箱");
      return;
    }
    if (!state.code) {
      setCodeError("请输入验证码");
      return;
    }
    Toast.loading({
      message: "加载中...",
      duration: 0,
      forbidClick: true,
      overlay: true,
    });
    signInByEmail();
  };

  return (
    <div className="bg-easbnb-brand/10 relative h-full">
      <div className="absolute inset-0 z-0 size-full bg-white " />
      <Helmet>
        <title>{genPageTitle("账号登录")}</title>
      </Helmet>
      <LoginBannerImg className="relative z-10 h-auto w-full object-scale-down" />
      <div className="relative z-10 -mt-9 rounded-t-[32px] bg-white p-8">
        <div className="mb-4 text-3xl font-medium">Hello~</div>
        <div className="mb-8 text-2xl font-medium">欢迎登录...</div>

        <div className="flex flex-col">
          <div className={cn("mb-6", emailError && "mb-2")}>
            <div className="bg-input-bg-base/5 rounded-2xl px-6 py-4">
              <input
                placeholder="请输入邮箱地址"
                inputMode="email"
                autoCapitalize="off"
                autoCorrect="off"
                value={state.email}
                onChange={(e) => {
                  setState({ email: e.target.value });
                  if (emailError && e.target.value) {
                    if (
                      emailError === "请输入正确的邮箱" &&
                      !isEmail(e.target.value)
                    ) {
                      return;
                    }
                    setEmailError("");
                  }
                }}
                className="text-text-1 placeholder:text-text-4 bg-transparent text-base"
              />
            </div>
            {Boolean(emailError) && (
              <div className="text-danger my-2 text-sm">{emailError}</div>
            )}
          </div>
          <div className="mb-8 flex flex-col">
            <div className="bg-input-bg-base/5 flex justify-between rounded-2xl px-6 py-4">
              <input
                placeholder="请输入验证码"
                value={state.code}
                autoCapitalize="off"
                autoCorrect="off"
                inputMode="numeric"
                onChange={(e) => {
                  setState({ code: e.target.value });
                  if (codeError && e.target.value) {
                    setCodeError("");
                  }
                }}
                className="text-text-1 placeholder:text-text-4 flex w-2/3 bg-transparent text-base"
              />

              {isUndefined(targetDate) ? (
                <div
                  className="text-easbnb-brand text-base"
                  onClick={onSendEmail}
                >
                  获取验证码
                </div>
              ) : (
                <div className="text-easbnb-brand text-base">
                  {Math.round(countdown / 1000)}s
                </div>
              )}
            </div>
            {Boolean(codeError) && (
              <div className="text-danger my-2 text-sm">{codeError}</div>
            )}
          </div>

          <div className="mb-24 flex justify-center">
            <button
              type="button"
              className="bg-resv-black rounded-full px-32 py-3.5 text-center text-base font-medium text-white"
              onClick={onLogin}
            >
              登录
            </button>
          </div>

          <div className="flex justify-center text-xs">
            <div className="text-text-3">登录即代表您已阅读并同意</div>
            <div className="text-easbnb-brand">《隐私政策》</div>
          </div>
        </div>
      </div>
    </div>
  );
};
