const checkDev = () =>
  window.location.hostname.endsWith(".easbnb.fun") ||
  window.location.hostname === "localhost" ||
  window.location.hostname.match(/^\d+\.\d+\.\d+\.\d+$/);

export const checkProd = () => !checkDev();

export const getStorageUrl = () =>
  checkProd() ? `https://file.easit.cc` : `https://test-file.easit.cc`;

export const getResvCommonUrl = () => `https://inn.zaito.net`;
// 等备案完了，注释掉上一行，改成下面这个
// export const getResvCommonUrl = () => `https://easinn.com`;
