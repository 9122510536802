import { Helmet } from "react-helmet";
import { isNil } from "lodash-es";
import { useNavigate } from "react-router-dom";
import { Dialog, Toast } from "react-vant";
import { SignService } from "@api/http_resv/sign/sign_srv";
import MePlaceholderAvatar from "@/assets/images/me_placeholder_avatar.webp";
import MeLoginAvatar from "@/assets/images/me_login_avatar.webp";
import CellArrowRightIcon from "@/assets/icons/cell_arrow_right.svg?react";
import PowerOffIcon from "@/assets/icons/power_off.svg?react";
import { PageLayout } from "@/components/layouts";
import { genPageTitle } from "@/utils";
import { useUserInfoStore } from "@/stores/useUserInfoStore";
import { PLACEHOLDER_TEXT } from "@/constants/text";
import { PATHS } from "@/constants/path";

export const Me = () => {
  const navigate = useNavigate();
  const userInfo = useUserInfoStore((s) => s.userInfo);
  const removeUserInfo = useUserInfoStore((s) => s.removeUserInfo);
  const avatar = isNil(userInfo) ? MePlaceholderAvatar : MeLoginAvatar;

  const onLogin = () => {
    if (isNil(userInfo)) {
      navigate(PATHS.LOGIN, { replace: true });
    }
  };

  const onLogout = () => {
    Dialog.confirm({
      title: "退出登录",
      message: "确定退出登录吗？",
      confirmButtonText: "确定",
      onConfirm: async () => {
        try {
          Toast.loading({
            message: "加载中...",
            duration: 0,
            forbidClick: true,
            overlay: true,
          });

          removeUserInfo();
          await SignService.SignOut({});
          Toast.clear();
        } catch (error) {
          Toast.clear();
        }
      },
      confirmButtonColor: "var(--wd-color-danger)",
    })
      .then(() => {})
      .catch(() => {});
  };

  const onContactSupportStaff = () => {
    void Dialog.alert({
      // TODO: 联系客服信息
      message: "联系客服信息",
      confirmButtonText: "确定",
      confirmButtonColor: "var(--wd-color-easbnb-brand)",
    });
  };

  return (
    <PageLayout>
      <Helmet>
        <title>{genPageTitle("我的")}</title>
      </Helmet>
      <div className="flex h-[280px] w-full items-center justify-center">
        <div
          className="flex flex-col items-center justify-center space-y-4"
          onClick={onLogin}
        >
          <img src={avatar} className="size-24 rounded-xl" alt="头像" />
          {isNil(userInfo) ? (
            <>
              <div className="text-text-1 text-xl font-medium">登录/注册</div>
              <div className="text-text-4 text-sm">
                点击登录/注册 享受更多精彩信息
              </div>
            </>
          ) : (
            <>
              <div className="text-text-1 text-xl font-medium">
                {userInfo?.user.name || PLACEHOLDER_TEXT}
              </div>
              <div className="text-text-2 flex flex-col items-center justify-center text-sm">
                <span>
                  {userInfo?.user.countryCode &&
                    `+${userInfo?.user.countryCode}`}
                  {userInfo?.user.phoneNumber ? userInfo?.user.phoneNumber : ""}
                </span>
                <span>{userInfo?.user.email || PLACEHOLDER_TEXT}</span>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="mb-3 rounded-xl bg-white px-3 py-6">
        <div
          className="flex items-center justify-between"
          onClick={onContactSupportStaff}
        >
          <div className="text-text-1 text-sm">联系客服</div>
          <CellArrowRightIcon className="size-[22px]" />
        </div>
      </div>

      {!isNil(userInfo) && (
        <div className="">
          <button
            type="button"
            className="flex w-full items-center justify-center space-x-2 rounded-xl bg-white py-3"
            onClick={onLogout}
          >
            <PowerOffIcon className="size-4" />
            <span className="text-sm text-[#F74D36]">退出登录</span>
          </button>
        </div>
      )}
    </PageLayout>
  );
};
