/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { webapi } from "@/utils/webapi";

export namespace SignService {
  // 通过邮箱登录
  export function SignInByEmail(req: SignInByEmailReq, config?: any) {
    return webapi.post<SignInByEmailResp>(
      "/api/v1/SignService_SignInByEmail",
      req,
      config,
    );
  }

  // 发送邮件
  export function SendEmail(req: SendEmailReq, config?: any) {
    return webapi.post<SendEmailResp>(
      "/api/v1/SignService_SendEmail",
      req,
      config,
    );
  }

  // 登出
  export function SignOut(req: SignOutReq, config?: any) {
    return webapi.post<SignOutResp>("/api/v1/SignService_SignOut", req, config);
  }
}
// SignInByEmailReq
export interface SignInByEmailReq {
  // 邮箱
  email: string;
  // 验证码
  code: string;
}

export interface SignInByEmailResp {}

export interface SendEmailReq {
  // 邮箱
  email: string;
}

export interface SendEmailResp {}

// SignOut
export interface SignOutReq {}

export interface SignOutResp {}
