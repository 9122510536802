import React from "react";
import { useNavigate } from "react-router-dom";
import { isNil } from "lodash-es";
import { Toast } from "react-vant";
import { useUserInfoStore } from "@/stores/useUserInfoStore";
import { PATHS } from "@/constants/path";

export const AuthGuard = ({ children }: React.PropsWithChildren) => {
  const navigate = useNavigate();
  const userInfo = useUserInfoStore((s) => s.userInfo);
  const isAuthenticated = !isNil(userInfo);

  React.useEffect(() => {
    if (!isAuthenticated) {
      Toast({ message: "请登录后查看" });
      navigate(PATHS.LOGIN, { replace: true });
    }
  }, [isAuthenticated, navigate]);

  return isAuthenticated ? children : null;
};
