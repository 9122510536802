import dayjs from "dayjs";
import { type FormattedRes } from "ahooks/lib/useCountDown";
import type * as order_type from "@api/http_resv/order/order_type";
import { Card } from "@/components/Card";
import { getCurrentTimeZoneDate, OrderStatusEnum } from "@/utils/orderStatus";
import { getMoneyText } from "@/utils/money";

export const OrderStatusCard = ({
  orderInDetail,
  orderStatus,
  formattedRes,
}: {
  orderInDetail: order_type.OrderInDetail;
  orderStatus?: OrderStatusEnum;
  formattedRes: FormattedRes;
}) => {
  if (orderStatus === OrderStatusEnum.Unpaid) {
    return (
      <Card>
        <div className="mb-3 flex items-center space-x-6">
          <span className="text-text-1 text-base font-medium">待付款</span>
          <span className="font-DINPro text-danger  text-2xl font-medium">
            {getMoneyText(
              orderInDetail.order.price.amount,
              orderInDetail.order.price.currency,
              {
                hidePlusSign: true,
                hideLabel: true,
              },
            )}
          </span>
        </div>
        <div className="text-text-666 text-sm">
          支付剩余：{formattedRes.minutes.toString().padStart(2, "0")}分
          {formattedRes.seconds.toString().padStart(2, "0")}
          秒，超时将自动取消订单
        </div>
      </Card>
    );
  }

  if (orderStatus === OrderStatusEnum.Cancelled) {
    // 已付款 + 已取消
    if (orderInDetail.order.paidAt) {
      return (
        <Card title="已取消">
          <div className="text-text-666 pt-3 text-sm">
            订单已被取消，请联系客服
          </div>
        </Card>
      );
    }
    return (
      <Card title="交易关闭">
        <div className="text-text-666 pt-3 text-sm">
          付款时间超时，订单自动关闭
        </div>
      </Card>
    );
  }

  if (orderStatus === OrderStatusEnum.WaitingCheckIn) {
    const h = Math.floor(orderInDetail.order.checkInTime / 60);
    const m = orderInDetail.order.checkInTime % 60;
    return (
      <Card>
        <div className="mb-3 flex items-center space-x-1">
          <span className="text-easbnb-brand text-2xl font-medium">
            {Math.max(
              dayjs(orderInDetail.order.startDate).diff(
                getCurrentTimeZoneDate(),
                "day",
              ),
              1,
            )}
            天
          </span>
          <span className="text-text-1 text-base font-medium">后入住</span>
        </div>
        <div className="text-text-666 text-sm">
          请您准备好相关证件于当天{h.toString().padStart(2, "0")}:
          {m.toString().padStart(2, "0")}前办理入住
        </div>
      </Card>
    );
  }

  if (orderStatus === OrderStatusEnum.CheckInToday) {
    const h = Math.floor(orderInDetail.order.checkInTime / 60);
    const m = orderInDetail.order.checkInTime % 60;
    return (
      <Card
        titleClassName="text-easbnb-brand"
        title="今日入住"
        titleColor="#6f9f0a"
      >
        <div className="text-text-666 pt-3 text-sm">
          请您准备好相关证件于今天{h.toString().padStart(2, "0")}:
          {m.toString().padStart(2, "0")}前办理入住
        </div>
      </Card>
    );
  }

  if (orderStatus === OrderStatusEnum.CheckOutToday) {
    const h = Math.floor(orderInDetail.order.checkOutTime / 60);
    const m = orderInDetail.order.checkOutTime % 60;
    return (
      <Card title="今日退房" titleColor="#FFC718">
        <div className="text-text-666 pt-3 text-sm">
          请在今天{h.toString().padStart(2, "0")}:
          {m.toString().padStart(2, "0")}之前完成退房
        </div>
      </Card>
    );
  }

  if (orderStatus === OrderStatusEnum.CheckIn) {
    return (
      <Card title="入住中" titleColor="#F74D36">
        <div className="text-text-666 pt-3 text-sm">
          欢迎入住！祝您有一个愉快的住宿体验
        </div>
      </Card>
    );
  }

  if (orderStatus === OrderStatusEnum.CheckedOut) {
    return (
      <Card title="已退房" titleColor="#979797">
        <div className="text-text-666 pt-3 text-sm">
          此笔订单已完成，期待您下次入住
        </div>
      </Card>
    );
  }

  return null;
};
