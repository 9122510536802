import { Helmet } from "react-helmet";
import { useSetState } from "ahooks";
import { useState } from "react";
import type * as order_type from "@api/http_resv/order/order_type";
import { List } from "react-vant";
import { OrderService } from "@api/http_resv/order/order_srv";
import { generatePath, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Empty from "@/assets/images/empty.svg?react";

import { PageLayout } from "@/components/layouts";
import { RoomCard, RoomCardHeaderWrapper } from "../components/RoomCard";
import { genPageTitle } from "@/utils";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "@/constants/pagination";
import { PATHS } from "@/constants/path";
import { toMMDD } from "@/lib/utils";
import { makeStorageUrl } from "@/utils/file";
import {
  getCurrentTimeZoneDate,
  getOrderStatus,
  OrderStatusEnum,
} from "@/utils/orderStatus";

const OrderStatusBadge = ({
  orderInList,
}: {
  orderInList: order_type.OrderInList;
}) => {
  const status = getOrderStatus({
    dateStart: orderInList.order.startDate,
    dateEnd: orderInList.order.endDate,
    paidAt: orderInList.order.paidAt,
    isCancelled: orderInList.order.isCancelled,
  });

  if (status === OrderStatusEnum.Unpaid) {
    return <div className="text-danger text-base font-medium">待付款</div>;
  }

  if (status === OrderStatusEnum.Cancelled) {
    return <div className="text-base font-medium text-[#979797]">交易关闭</div>;
  }

  if (status === OrderStatusEnum.WaitingCheckIn) {
    return (
      <div className="text-text-1 text-base font-medium">
        <span className="text-easbnb-brand">
          {Math.max(
            dayjs(orderInList.order.startDate).diff(
              getCurrentTimeZoneDate(),
              "day",
            ),
            1,
          )}
          天
        </span>
        <span className="ml-1 text-sm">后入住</span>
      </div>
    );
  }

  if (status === OrderStatusEnum.CheckInToday) {
    return (
      <div className="text-easbnb-brand text-base font-medium">今日入住</div>
    );
  }

  if (status === OrderStatusEnum.CheckIn) {
    return <div className="text-base font-medium text-[#F74D36]">入住中</div>;
  }

  if (status === OrderStatusEnum.CheckOutToday) {
    return <div className="text-base font-medium text-[#F9B908]">今日退房</div>;
  }

  if (status === OrderStatusEnum.CheckedOut) {
    return <div className="text-base font-medium text-[#979797]">已退房</div>;
  }

  return null;
};

export const OrderList = () => {
  const navigate = useNavigate();

  const [pagination, setPagination] = useSetState<{
    page: number;
    pageSize: number;
  }>({
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [finished, setFinished] = useState(false);
  const [orders, setOrders] = useState<order_type.OrderInList[]>([]);

  const noMoreTipVisible = finished && orders.length;

  const onGoToOrderDetail = (orderId: string) => {
    const path = generatePath(`${PATHS.ORDER}/:id`, {
      id: orderId,
    });
    navigate(path, { replace: true });
  };

  if (finished && orders.length === 0) {
    return (
      <div className="flex h-[50vh] w-full items-center justify-center">
        <Helmet>
          <title>{genPageTitle("订单列表")}</title>
        </Helmet>

        <div className="flex flex-col items-center pt-40">
          <Empty />
          <div className="text-text-4 mt-3 text-sm">暂无订单</div>
        </div>
      </div>
    );
  }

  return (
    <PageLayout className="px-0">
      <Helmet>
        <title>{genPageTitle("订单列表")}</title>
      </Helmet>

      {/* 列表 */}
      <div className="bg-page-bg flex w-full flex-1 flex-col space-y-6 px-4">
        <List
          finished={finished}
          onLoad={async () => {
            const resp = await OrderService.List({
              current: pagination.page,
              pageSize: pagination.pageSize,
            });
            const data = [...orders];
            data.push(...(resp.orders || []));
            setOrders(data);
            if (data.length >= resp.total) {
              setFinished(true);
            } else {
              setPagination({
                page: pagination.page + 1,
              });
            }
          }}
        >
          {orders.map((orderInList) => (
            <RoomCard
              key={orderInList.order.id}
              className="mb-3"
              imageUri={makeStorageUrl(orderInList.roomImageUri)}
              room={orderInList.room}
              quotedPrice={orderInList.order.price}
              disableLink={true}
              header={
                <RoomCardHeaderWrapper>
                  <div className="text-text-666 text-sm">
                    {toMMDD(orderInList.order.startDate, { splitStr: "/" })}-
                    {toMMDD(orderInList.order.endDate, { splitStr: "/" })} 共
                    {orderInList.order.stays}晚
                  </div>

                  <OrderStatusBadge orderInList={orderInList} />
                </RoomCardHeaderWrapper>
              }
              extra={
                getOrderStatus({
                  dateStart: orderInList.order.startDate,
                  dateEnd: orderInList.order.endDate,
                  paidAt: orderInList.order.paidAt,
                  isCancelled: orderInList.order.isCancelled,
                }) === OrderStatusEnum.Unpaid && (
                  <div className="flex justify-end pt-4">
                    <button
                      type="button"
                      className="bg-resv-black rounded-full px-7 py-2 text-sm font-medium text-white"
                    >
                      去支付
                    </button>
                  </div>
                )
              }
              onClick={() => onGoToOrderDetail(orderInList.order.id)}
            />
          ))}
        </List>

        {noMoreTipVisible && (
          <div className="text-text-3 flex justify-center py-6 text-sm">
            已经到底啦～
          </div>
        )}
      </div>
    </PageLayout>
  );
};
