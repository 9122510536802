import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEventListener } from "ahooks";
import { useHistoryStackStore } from "@/stores/useHistoryStackStore";
import { PATHS } from "@/constants/path";

export const BackButtonListener = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const push = useHistoryStackStore((s) => s.pushHistoryStack);
  const historyStack = useHistoryStackStore((s) => s.historyStack);

  useEffect(() => {
    push(location.pathname);
  }, [location, push]);

  useEventListener(
    "popstate",
    (ev) => {
      if (historyStack.length >= 2) {
        const orderDetailPattern = new RegExp(`^${PATHS.ORDER}/[^/]+$`);
        // 任意页面跳转到订单详情页，都返回到订单列表
        if (orderDetailPattern.test(historyStack[historyStack.length - 2]!)) {
          navigate(PATHS.ORDER, { replace: true });
        }
      }
    },
    { target: window },
  );

  return <Outlet />;
};
