import { NavBar, Toast } from "react-vant";

import { Helmet } from "react-helmet";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useRequest, useDebounceFn } from "ahooks";
import { RoomService } from "@api/http_resv/room/room_srv";
import { isUndefined } from "lodash-es";
import clsx from "clsx";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { Loading } from "@/components/Loading";
import { CustomSkeleton } from "@/components/Skeleton";
import { TenantCount } from "@/components/TenantCount";
import { type DateRange, LodgingDates } from "@/components/LodgingDates";
import { RoomSwiper } from "@/components/RoomSwiper";
import { Card } from "@/components/Card";
import LocationIcon from "@/assets/icons/location.svg?react";
import SubwayIcon from "@/assets/icons/subway.svg?react";
import AlertIcon from "@/assets/icons/alert.svg?react";
import { FixedBottomWrapper } from "@/components/FixedBottomWrapper";
import { PATHS } from "@/constants/path";
import { genPageTitle } from "@/utils";
import { stringify } from "@/lib/utils";
import { getMoneyText } from "@/utils/money";
import { getRoomInfo } from "@/utils/roomInfo";
import { useUrlSearchState } from "@/hooks/useUrlSearchState";

export interface RoomSearchState {
  startDate?: string;
  endDate?: string;
  adultCount: number;
}

export const RoomDetail = () => {
  const navigate = useNavigate();

  const params = useParams<{ id: string }>();
  const id = params.id!;

  const { priceQuery, queryObj } = useUrlSearchState();

  const [dateState, setDateState] = useState<DateRange | undefined>(
    priceQuery.startDate && priceQuery.endDate
      ? {
          startDate: priceQuery.startDate,
          endDate: priceQuery.endDate,
        }
      : undefined,
  );

  const [countState, setCountState] = useState(priceQuery?.adultCount || 1);

  const nights = useMemo(() => {
    return dateState
      ? dayjs(dateState.endDate).diff(dateState.startDate, "day")
      : 0;
  }, [dateState]);

  const { data, loading, error, run } = useRequest(
    async () => {
      const resp = await RoomService.Get({
        id,
        priceQuery: {
          startDate: dateState?.startDate ?? "",
          endDate: dateState?.endDate ?? "",
          adultCount: countState,
        },
      });

      return resp.room;
    },
    {
      refreshDeps: [dateState],
    },
  );

  const { run: runDebounce } = useDebounceFn(run, { wait: 300 });

  const roomInfoText = getRoomInfo({
    area: data?.room.roomSize,
    bed: data?.room.singleBedCount,
    doubleBed: data?.room.doubleBedCount,
  });

  const goToOrderConfirm = () => {
    if (isUndefined(priceQuery)) {
      Toast({
        type: "fail",
        message: "获取房间价格信息异常",
      });
      return;
    }

    if (!dateState) {
      return;
    }
    const query = stringify({ ...queryObj, ...priceQuery });
    const path = generatePath(`${PATHS.ORDER_CONFIRM}/:id`, { id });
    navigate(`${path}?${query}`, { replace: true });
  };

  const handleCountChange = (value: number) => {
    if (value <= 0) return;
    setCountState(value);
    runDebounce();

    const query = stringify({
      ...queryObj,
      ...dateState,
      adultCount: value,
    });
    const path = generatePath(`${PATHS.ROOM}/:id`, { id });
    navigate(`${path}?${query}`, { replace: true });
  };

  const handleDateChange = (value: DateRange) => {
    setDateState(value);
    const query = stringify({
      ...queryObj,
      ...value,
      adultCount: countState,
    });

    const path = generatePath(`${PATHS.ROOM}/:id`, { id });
    navigate(`${path}?${query}`, { replace: true });
  };

  if (error) {
    return (
      <div className="flex h-[50vh] w-full items-center justify-center">
        <Helmet>
          <title>{genPageTitle("房间详情")}</title>
        </Helmet>
        <div className="text-text-4 text-sm">无法获取房间信息</div>
      </div>
    );
  }

  return (
    <div className="h-screen overflow-y-auto">
      <Helmet>
        <title>{genPageTitle("房间详情2")}</title>
      </Helmet>

      {isUndefined(data) && (
        <div className="flex h-1/2 w-full items-center justify-center">
          <Loading />
        </div>
      )}

      {data && (
        <div>
          <NavBar
            title={data?.room.title}
            leftText="返回"
            fixed={true}
            placeholder={true}
            zIndex={50}
            onClickLeft={() => {
              setDateState(undefined);
              navigate(
                `${PATHS.ROOM_SEARCH}?${stringify({
                  ...queryObj,
                  ...priceQuery,
                })}`,
                { replace: true },
              );
            }}
          />
          <div className="relative">
            {Boolean(!data?.room.isOnSale) && (
              <div className="absolute left-1/2 top-1/2 z-10 flex -translate-x-1/2 -translate-y-1/2  items-center justify-center rounded-3xl bg-black/40 px-6 py-2 font-normal  text-white">
                该房间已下架
              </div>
            )}
            <RoomSwiper value={data.images} />
          </div>

          <div className="px-4 py-3 pb-56">
            <div className="text-text-1 mb-2 text-xl font-medium">
              {data?.room.title}
            </div>
            <div className=" mb-3 flex items-center space-x-2">
              {data.room.roomSize > 0 && (
                <span className="text-text-666 text-sm">{roomInfoText}</span>
              )}
              {data.room.personCapacity > 0 && (
                <span className="text-easbnb-brand text-sm">
                  可住{data?.room.personCapacity}人
                </span>
              )}
            </div>

            {Boolean(data?.room.isOnSale) && (
              <Card className="mb-3">
                {!(dateState?.startDate && dateState?.endDate) && (
                  <div className="mx-[-4px] flex h-7 items-center rounded-full border border-[#FDF6EF] bg-[#FDF6EF]">
                    <AlertIcon className="mx-2 my-1.5 size-4" />
                    <span className="text-xs text-[#EC642B] ">
                      请选择预定日期和入住人数。
                    </span>
                  </div>
                )}
                {dateState?.startDate &&
                  dateState?.endDate &&
                  !data.canBook && (
                    <div className="mx-[-4px] flex h-7 items-center rounded-full border border-[#FDF6EF] bg-[#FDF6EF]">
                      <AlertIcon className="mx-2 my-1.5 size-4" />
                      <span className="text-xs text-[#EC642B] ">
                        该日期已经被预定，请重新选择。
                      </span>
                    </div>
                  )}

                <LodgingDates
                  roomId={id}
                  value={dateState}
                  onChange={handleDateChange}
                />
                <TenantCount value={countState} onChange={handleCountChange} />
              </Card>
            )}

            {Boolean(data?.room.address || data?.room.traffic) && (
              <Card title="交通方式" className="mb-3">
                {Boolean(data?.room.address) && (
                  <div className="my-3 flex items-start">
                    <LocationIcon className="mr-1 mt-0.5 size-4" />
                    <div className="text-text-666 text-sm">
                      {data?.room.address}
                    </div>
                  </div>
                )}
                {Boolean(data?.room.traffic) && (
                  <div className="mb-3 flex items-start">
                    <SubwayIcon className="mr-1 mt-0.5 size-4" />
                    <div className="text-text-666 flex-1 whitespace-pre-line text-sm">
                      {data?.room.traffic}
                    </div>
                  </div>
                )}
              </Card>
            )}

            {Boolean(data?.introduction) && (
              <Card title="房间介绍" className="mb-3">
                <div className="text-text-666 whitespace-pre-line pt-3 text-sm">
                  {data?.introduction}
                </div>
              </Card>
            )}

            {Boolean(data?.policy) && (
              <Card title="退订政策">
                <div className="text-text-666 whitespace-pre-line pt-3 text-sm">
                  {data?.policy}
                </div>
              </Card>
            )}
          </div>

          {/* 根据是canBook字符 和 isOnSale字符   判断是否可预定 */}
          {Boolean(data?.canBook) && Boolean(data?.room.isOnSale) && (
            <FixedBottomWrapper className="flex w-full items-center justify-between bg-white px-6 py-3">
              {data.lowestPrice && (
                <div className="flex flex-row items-center">
                  <CustomSkeleton
                    row={1}
                    rowWidth={148}
                    rowHeight={28}
                    loading={loading}
                    style={{ marginBottom: 8 }}
                    $color="#FFEFF2"
                  >
                    <span className="font-DINPro text-danger mr-1 text-2xl font-medium">
                      {getMoneyText(
                        data.lowestPrice.amount,
                        data.lowestPrice.currency,
                        { hidePlusSign: true },
                      )}
                    </span>
                    <span className="text-text-2 flex flex-col items-center space-x-2 text-xs">
                      起/晚
                    </span>
                  </CustomSkeleton>
                </div>
              )}

              {data.quotedPrice && data.basePrice && (
                <div className="flex flex-col ">
                  <div className="font-DINPro text-danger text-2xl font-medium">
                    <CustomSkeleton
                      row={1}
                      rowWidth={128}
                      rowHeight={28}
                      loading={loading}
                      style={{ marginBottom: 8 }}
                      $color="#FFEFF2"
                    >
                      {getMoneyText(
                        data.quotedPrice.amount,
                        data.quotedPrice.currency,
                        { hidePlusSign: true },
                      )}
                    </CustomSkeleton>
                  </div>
                  <div className="text-text-2 mb-2 flex flex-col items-start space-x-2  text-xs">
                    {data.quotedPrice.amount !== data.basePrice.amount && (
                      <span className="line-through">
                        {getMoneyText(
                          data.basePrice.amount,
                          data.basePrice.currency,
                          { hideLabel: true, hidePlusSign: true },
                        )}
                      </span>
                    )}
                    <CustomSkeleton
                      row={1}
                      rowWidth={64}
                      rowHeight={8}
                      loading={loading}
                    >
                      <span>
                        均价
                        {getMoneyText(
                          data.quotedPrice.amount / nights,
                          data.quotedPrice.currency,
                          { hideLabel: true, hidePlusSign: true },
                        )}
                        /晚
                      </span>
                    </CustomSkeleton>
                  </div>
                </div>
              )}
              <button
                type="button"
                className={clsx(
                  "rounded-full px-16 py-3 text-base font-medium text-white",
                  dateState?.startDate && dateState?.endDate && !loading
                    ? "bg-resv-black"
                    : "bg-[#979BAD]",
                )}
                onClick={goToOrderConfirm}
              >
                预定
              </button>
            </FixedBottomWrapper>
          )}
        </div>
      )}
    </div>
  );
};
