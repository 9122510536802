import { type FC, useMemo } from "react";
import dayjs from "dayjs";
import NiceModal from "@ebay/nice-modal-react";
import Calendar from "@/assets/icons/calendar.svg?react";
import {
  DateRangePopup,
  type DateRangePopupProps,
} from "@/components/DateRangePopup";
import { toYYYYMMDD } from "@/lib/utils";

export interface DateRange {
  startDate: string;
  endDate: string;
}

export interface LodgingDatesProps {
  /** 传 roomId 后，自动开启可用性校验 */
  roomId?: string;
  value?: DateRange;
  onChange?: (value: DateRange) => void;
}

export const LodgingDates: FC<LodgingDatesProps> = ({
  onChange,
  value,
  roomId,
}) => {
  const nights = useMemo(() => {
    return value?.startDate && value?.endDate
      ? dayjs(value.endDate).diff(value.startDate, "day")
      : 0;
  }, [value]);

  const showDateRangePopup = async () => {
    const params: DateRangePopupProps = {
      roomId,
      value,
    };
    const res: DateRange = await NiceModal.show(DateRangePopup, params);
    onChange?.(res);
  };

  return (
    <div
      className="border-b-text-4/20 mb-3  border-b py-3"
      onClick={showDateRangePopup}
    >
      <div className="flex items-center">
        <Calendar className="mr-3 size-6" />
        {value?.startDate && value?.endDate ? (
          <div className="flex flex-1 items-center justify-between">
            <div className="flex items-center space-x-3">
              <div className="text-easbnb-brand text-base font-medium">
                {toYYYYMMDD(dayjs(value.startDate))}
              </div>
            </div>

            <div className="flex flex-1 justify-center">
              <div className="border-text-4 text-text-666 flex rounded-full border px-3 py-1 text-xs">
                共{nights}晚
              </div>
            </div>

            <div className="flex items-center space-x-0.5">
              <div className="text-easbnb-brand text-base font-medium">
                {toYYYYMMDD(dayjs(value.endDate))}
              </div>
            </div>
          </div>
        ) : (
          <div className="text-text-4 text-base">选择入离日期</div>
        )}
      </div>
    </div>
  );
};
