import { LocationKind } from "@api/http_resv/room/room_srv";
import { PayKind } from "@api/http_pms/const/const_enum";
import IconLocation from "@/assets/icons/search_location.svg";
import IconSubway from "@/assets/icons/search_subway.svg";
import IconBus from "@/assets/icons/search_bus.svg";
import IconFacility from "@/assets/icons/search_facility.svg";

import PayWeChatIcon from "@/assets/icons/pay_wechat.svg";
import PayAlipayIcon from "@/assets/icons/pay_alipay.svg";
import PayCreditCardIcon from "@/assets/icons/pay_credit_card.svg";

export type ConstOptionMap<
  K extends PropertyKey,
  T extends Record<string, any> = {},
> = Record<K, ConstOption<K, T>>;

export type ConstOption<
  K extends PropertyKey,
  T extends Record<string, any> = {},
> = {
  label: string;
  value: K;
  icon?: string;
} & T;

export const SEARCH_RESULT_ICON: ConstOptionMap<LocationKind> = {
  [LocationKind.Location]: {
    value: LocationKind.Location,
    label: "地点",
    icon: IconLocation,
  },
  [LocationKind.Subway]: {
    value: LocationKind.Subway,
    label: "地铁",
    icon: IconSubway,
  },
  [LocationKind.Bus]: {
    value: LocationKind.Bus,
    label: "公交",
    icon: IconBus,
  },
  [LocationKind.Facility]: {
    value: LocationKind.Facility,
    label: "设施",
    icon: IconFacility,
  },
};

export const PAYCHANNEL_ICON: ConstOptionMap<PayKind> = {
  [PayKind.UnknownPayKind]: {
    value: PayKind.UnknownPayKind,
    label: "未知",
    icon: PayWeChatIcon,
  },
  [PayKind.WechatPay]: {
    value: PayKind.WechatPay,
    label: "微信支付",
    icon: PayWeChatIcon,
  },
  [PayKind.AliPay]: {
    value: PayKind.AliPay,
    label: "支付宝支付",
    icon: PayAlipayIcon,
  },
  [PayKind.Credit]: {
    value: PayKind.Credit,
    label: "信用卡支付",
    icon: PayCreditCardIcon,
  },
};
