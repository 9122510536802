import dayjs from "dayjs";
import { isString, isUndefined } from "lodash-es";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
// import type * as common_type from "@api/http_resv/common/common_type";
import { parseSearchStr, parseToNumber, toYYYYMMDD } from "@/lib/utils";

const parseSearchToRoomSearchState = (
  searchStr: string,
): Record<string, any> => {
  const obj = parseSearchStr(searchStr);
  const startDateStr = obj.startDate;
  const endDateStr = obj.endDate;
  const adultCount = parseToNumber(obj.adultCount);
  const address = isString(obj.address) ? obj.address : undefined;
  const lat = parseToNumber(obj.lat);
  const lng = parseToNumber(obj.lng);

  let startDate = "";
  let endDate = "";

  if (startDateStr && endDateStr) {
    const date1 = dayjs(startDateStr as string);
    const date2 = dayjs(endDateStr as string);
    if (date1.isValid() && date2.isValid()) {
      startDate = toYYYYMMDD(date1);
      endDate = toYYYYMMDD(date2);
    }
  }

  return {
    startDate,
    endDate,
    adultCount,
    address,
    lat,
    lng,
    ...obj,
  };
};

export const useUrlSearchState = () => {
  const location = useLocation();
  const queryObj = parseSearchToRoomSearchState(location.search);

  const { startDate, endDate, adultCount, lat, lng } = queryObj;

  const priceQuery = useMemo(() => {
    const count = parseToNumber(adultCount);

    return {
      startDate,
      endDate,
      adultCount: count!,
    };
  }, [startDate, endDate, adultCount]);

  const point = useMemo(() => {
    if (isUndefined(lat) || isUndefined(lng)) {
      return undefined;
    }
    return {
      lat,
      lng,
    };
  }, [lat, lng]);

  return { priceQuery, queryObj, point };
};
