import NiceModal, { useModal } from "@ebay/nice-modal-react";
import clsx from "clsx";
import { Popup } from "react-vant";
import { isArray } from "lodash-es";
import { useState, useMemo } from "react";
import dayjs from "dayjs";
import { useRequest } from "ahooks";
import { RoomService } from "@api/http_resv/room/room_srv";
import { Loading } from "../Loading";
import { CalendarCssWrapper } from "./styled";
import {
  isHotelIndustryWeekend,
  toYYYYMMDD,
  customDateFormat,
} from "@/lib/utils";
import { type DateRange } from "../LodgingDates";

export interface DateRangePopupProps {
  value?: DateRange;
  roomId?: string;
}

export const DateRangePopup = NiceModal.create(
  ({ value: initValue, roomId }: DateRangePopupProps) => {
    const modal = useModal();

    const [newDates, setNewDates] = useState(() => {
      return initValue?.startDate && initValue?.endDate
        ? [
            dayjs(initValue.startDate).toDate(),
            dayjs(initValue.endDate).toDate(),
          ]
        : undefined;
    });

    const nights = useMemo(
      () => (newDates ? dayjs(newDates[1]).diff(dayjs(newDates[0]), "day") : 0),
      [newDates],
    );

    const { data: disabledDays, loading } = useRequest(
      async () => {
        const res = await RoomService.ListRoomDayClose({
          startDate: dayjs().format("YYYY-MM-DD"),
          endDate: dayjs().add(6, "months").format("YYYY-MM-DD"),
          roomId: roomId!,
        });
        return res.days;
      },
      {
        ready: Boolean(roomId),
      },
    );

    const canNotBook = useMemo(() => {
      if (!roomId || !newDates) return false;
      const start = toYYYYMMDD(newDates[0]);
      const end = toYYYYMMDD(newDates[1]);
      return disabledDays?.some((cur) => cur >= start && cur <= end);
    }, [newDates]);

    const onSubmit = () => {
      if (canNotBook || !newDates) return;
      modal.resolve({
        startDate: toYYYYMMDD(newDates[0]),
        endDate: toYYYYMMDD(newDates[1]),
      });
      void modal.hide();
    };

    const footer = (
      <div className="flex h-20 w-full items-center justify-between px-2 py-3">
        <div>
          {!newDates ? null : canNotBook ? (
            <span className="mr-2 text-sm text-[#EC642B]">已选日期不可定</span>
          ) : (
            <span className="text-text-1 mr-2 text-sm">
              {nights > 0 ? `已选: ${nights + 1}天${nights}晚` : null}
            </span>
          )}
        </div>

        <div
          onClick={onSubmit}
          className={clsx(
            "flex h-12 w-40 items-center justify-center rounded-full border text-base font-medium text-white",
            canNotBook || !newDates
              ? "border-[#979BAD] bg-[#979BAD]"
              : " border-text-1  bg-black",
          )}
        >
          确定
        </div>
      </div>
    );

    const [minDate, maxDate] = useMemo(
      () => [dayjs().toDate(), dayjs().add(6, "months").toDate()],
      [],
    );
    return (
      <Popup
        position="bottom"
        className="h-4/5 w-full"
        visible={modal.visible}
        closeable={true}
        round={true}
        onClose={() => {
          modal.reject();
          void modal.hide();
        }}
        onClosed={modal.remove}
        safeAreaInsetBottom={true}
      >
        {loading ? (
          <div className="flex h-1/2 w-full items-center justify-center">
            <Loading />
          </div>
        ) : (
          <CalendarCssWrapper
            $canNotBook={canNotBook}
            onSelect={(v) => {
              setNewDates(isArray(v) && v[1] ? v : undefined);
            }}
            defaultValue={newDates}
            poppable={false}
            type="range"
            minDate={minDate}
            maxDate={maxDate}
            color={canNotBook ? "#EC642B" : "#6F9F0A"}
            showConfirm={false}
            title="选择入离日期"
            footer={footer}
            formatter={(day) => {
              if (
                day.date &&
                dayjs().isBefore(day.date) &&
                dayjs().add(6, "months").isAfter(day.date) &&
                isHotelIndustryWeekend(day.date)
              ) {
                day.className = "isHotelIndustryWeekend";
              }
              if (
                disabledDays?.includes(
                  customDateFormat(day.date as unknown as string),
                )
              ) {
                day.bottomInfo = "已满房";
                day.type = "disabled";
                day.className = "alreadyBooked";
              }

              if (day.type === "start") {
                day.className = "checkIn";
              } else if (day.type === "end") {
                day.className = "checkOut";
              }

              return day;
            }}
          />
        )}
      </Popup>
    );
  },
);
