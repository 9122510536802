import { useState, useMemo } from "react";
import { Image, ImagePreview, NavBar } from "react-vant";
import { Helmet } from "react-helmet";
import { useRequest } from "ahooks";
import { RoomService } from "@api/http_resv/room/room_srv";
import { isNil } from "lodash-es";

import {
  useLocation,
  useNavigate,
  generatePath,
  useParams,
} from "react-router-dom";
import clsx from "clsx";
import { useSwiperData } from "@/hooks/useSwiperData";
import { stringify } from "@/lib/utils";
import { genBigRoomSwiperCoverUrl } from "@/utils/file";
import {
  type ImagesGroupItem,
  type InitImageProps,
} from "@/components/RoomSwiper";
import { genPageTitle } from "@/utils";
import { PATHS } from "@/constants/path";

export const RoomPhoto = () => {
  interface RoomPhotoState {
    data: InitImageProps[];
    params: Record<string, string>;
  }

  const navigate = useNavigate();
  const location = useLocation();
  const roomPhotoState = location.state as RoomPhotoState;

  const params = useParams<{ id: string }>();

  const id = params.id!;
  const [active, setActive] = useState("");
  const { data } = useRequest(
    async () => {
      const resp = await RoomService.Get({
        id,
      });

      return resp.room.images;
    },
    {
      ready: isNil(roomPhotoState),
    },
  );

  const initPhotoData = useMemo(() => {
    if (isNil(roomPhotoState?.data)) {
      return data;
    }
    return roomPhotoState.data;
  }, [data]);

  const groupPhotoData = useSwiperData(
    initPhotoData ?? [],
  ) as ImagesGroupItem[];

  const onTabClick = (item: ImagesGroupItem) => {
    setActive(item.label);
    const el = document.getElementById(item.label);
    if (el) {
      const height = el.offsetTop - 94;
      window.scrollTo({
        top: height,
        behavior: "smooth",
      });
    }
  };

  return (
    <div>
      <Helmet>
        <title>{genPageTitle("房间照片")}</title>
      </Helmet>
      <NavBar
        title="房间照片"
        leftText="返回"
        fixed={true}
        placeholder={true}
        zIndex={50}
        onClickLeft={() => {
          const query = stringify(roomPhotoState?.params ?? {});
          const path = generatePath(`${PATHS.ROOM}/:id`, {
            id,
          });
          navigate(`${path}?${query}`, { replace: true });
        }}
      />
      <div className="flex flex-col ">
        <div className="bg-page-bg no-scrollbar fixed z-50 flex h-12 w-full items-center overflow-x-auto px-4">
          <div className="no-scrollbar inline-block w-full space-x-3 overflow-y-hidden whitespace-nowrap">
            {groupPhotoData.map((item: ImagesGroupItem, index) => (
              <div
                key={item.name}
                onClick={() => {
                  onTabClick(item);
                }}
                className={clsx(
                  "inline-block h-6 rounded-lg px-2 py-0.5 text-sm",
                  active === item.label
                    ? "bg-easbnb-brand text-white"
                    : "text-text-2 bg-[#F0F2F4]",
                  // 第一个元素不给左边距
                  index === 0 && "ml-0",
                )}
              >
                {item.name}&nbsp;({item.images.length})
              </div>
            ))}
          </div>
        </div>

        {groupPhotoData.map((item: ImagesGroupItem) => (
          <PhotoItem
            initData={initPhotoData as InitImageProps[]}
            key={item.name}
            name={item.name}
            images={item.images}
            label={item.label}
          />
        ))}
      </div>
    </div>
  );
};

const PhotoItem = ({
  name,
  label,
  images,
  initData,
}: ImagesGroupItem & {
  initData: InitImageProps[];
}) => {
  return (
    <div id={label} className="bg-page-bg relative top-12 px-4">
      <span>
        {name}&nbsp;({images.length})
      </span>
      <div className="mb-6 mt-3">
        <div className="flex w-full flex-wrap gap-3">
          {images.map((item, index) => (
            <div
              key={item.index}
              className="h-32 rounded-full"
              style={{
                width: "calc(50% - 6px)",
              }}
            >
              <Image
                src={genBigRoomSwiperCoverUrl(item.uri)}
                fit="cover"
                radius="8px"
                // TODO: 有没有办法让图片自动加载
                // lazyload={true}
                onClick={() => {
                  ImagePreview.open({
                    images: initData.map((image) =>
                      genBigRoomSwiperCoverUrl(image.uri),
                    ),
                    startPosition: item.index,
                  });
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
